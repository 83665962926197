const DESKTOP_WIDTH = 768

export default class utils {
    constructor() {

    }

    static desktop = () => utils.getWidth() > DESKTOP_WIDTH

    static mobile = () => utils.getWidth() <= DESKTOP_WIDTH

    static getWidth = () => (Math.max(document.documentElement.clientWidth, window.innerWidth || 0))

    static getHeight = () => (Math.max(document.documentElement.clientWidth, window.innerWidth || 0))
}