import React from "react"
import styled from "styled-components";

const styles = {
    outerContainer: {
        width: '100%',
        fontFamily: 'Lato',
    },
    containerTwo: {
        padding: '0 20px',
    },
    innerContainer: {
        padding: 20,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flexBasis: '100%',
    },
    mainHeader: {
        fontSize: 20,
        fontWeight: '600',
        marginBottom: '10px',
    },
}

const ContainerStyle = styled.div`
max-width: 1024px;
margin-left: auto;
margin-right: auto;
box-shadow: rgb(0, 0, 0, 0.15) 0px 2px 8px;
background-color: white;
:hover {
    background-color: #EFEFEF !important;
    box-shadow: rgb(0, 0, 0, 0.2) 0px 2px 8px;
}
`

class AccountCreationBanner extends React.Component {

    render () {
        return (
            <div style={styles.outerContainer}>
                <div style={styles.containerTwo}>
                    <ContainerStyle>
                        <a href={'/users/new'}>
                            <div style={styles.innerContainer}>
                                <div style={styles.mainHeader}>
                                    New to Prestige?
                                </div>
                                <div>
                                    Click here to sign up and rank companies
                                </div>
                            </div>
                        </a>
                    </ContainerStyle>
                </div>
            </div>
        );
    }
}

export default AccountCreationBanner
