import React from "react"
import styled from 'styled-components'
import utils from './../helpers/utils'

const styles = {
    leftInfo: {
        alignItems: 'center',
        height: 'inherit',
        padding: '0 15px',
    },
    mainInfo: {
        marginLeft: 20,
    },
    elo: {
        fontWeight: '400',
        fontSize: '14px',
        color: '#999',
        marginTop: '4px',
    },
    companyInfo: {
        display: 'inline-flex',
        width: '140px',
        alignItems: 'center',
    },
    centeredColumn: {
        padding: '0 10px',
        textAlign: 'center',
    },
    column: {
        padding: '0 15px',
    },
    rightInfo: {
        padding: '0 15px',
    },
    jobs: {
        padding: '0 15px',
        maxWidth: '200px',
        fontSize: '12px',
        wordWrap: 'break-word',
    },
    index: {
        color: '#999',
    },
    careersLink: {
        color: '#70b5ff',
    },
    quickViewJobs: {
        color: 'white',
        fontSize: '12px',
        fontWeight: '600',
        backgroundColor: '#70b5ff',
        padding: '10px',
        display: 'inline-block',
        marginBottom: '4px',
    },
    secondaryHover: {
        fontSize: '12px',
        fontWeight: '600',
        backgroundColor: 'white',
        padding: '10px',
        display: 'inline-block',
        marginBottom: '4px',
    },
    containerStyle: {
        backgroundColor: 'white',
        minWidth: '300px',
        maxWidth: '300px',
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        cursor: 'pointer',
    },
    infoTitle: {
        color: '#AAA',
        fontSize: '14px'
    },
    infoInfo: {
        color: '#666',
        fontWeight: '600'
    }
}

const JobsHover = styled.div`
:hover {
    background-color: #7abaff !important;
}
`

const SecondaryHover = styled.div`
border: 1px solid #70b5ff;
color: #70b5ff;
:hover {
    color: white !important;
    background-color: #70b5ff !important;
}
`

const HoverStyle = styled.div`
transition: 0.3s;
:hover {
    margin-top: -10px;
    margin-bottom: 10px;
    box-shadow: rgb(0 0 0 / 23%) 0px 2px 12px !important;
}
`

const Name = styled.div`
font-weight: 500;
font-size: 20px;
@media (max-width: 768px) {
    font-size: 18px;
  }
`

class CompanyListItem extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            startAnimation: false
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                startAnimation: true
            })
        }, Math.floor(Math.random() * 500))
    }

    convertSalaryToString = salary => {
        salary = parseInt(salary)
        if (isNaN(salary)) {
            return '???'
        }
        salary /= 1000
        return `$${salary}K`
    }

    limitSize = (string, chars) => {
        string = string.substr(0, chars)
        if (string.length === chars) {
            string += '...'
        }
        return string
    }

    renderJobsButton = hasJobs => {
        if (hasJobs) {
            return (<JobsHover style={styles.quickViewJobs}>
                Quick View Jobs
            </JobsHover>)
        } else {
            return (<SecondaryHover style={styles.secondaryHover}>
                Company Details
            </SecondaryHover>)
        }
    }

    renderRankColumn = () => {
        const tiers = [4, 20, 52, 100, 10000]

        const tierColors = ['#ff5656', '#56b9ff', '#ffab56', '#ff56cf', '#8656ff']

        let tier = 10

        for (let i = 0; i < tiers.length; i++) {
            if (Number(this.props.rank) <= tiers[i]) {
                tier = i + 1
                break
            }
        }

        return (
            <div style={{...styles.leftInfo, textAlign: 'center'}}>
                <div style={{...styles.index, fontSize: '12px'}}>
                    {`Rank #${this.props.rank}`}
                </div>
                <div style={{fontWeight: '900', color: tierColors[tier-1]}}>
                    {`Tier ${tier}`}
                </div>
            </div>
        )
    }

    renderCompanyColumn = () => {
        const logoUrl = this.props.logo_url ? this.props.logo_url : `//logo.clearbit.com/${this.props.link}`
        return (
            <div style={styles.leftInfo}>
                <div style={styles.companyInfo}>
                    <img width="45px" style={{objectFit: 'contain', borderRadius: this.props.rounded ? '50%' : '0%'}} src={logoUrl}/>
                    <div style={styles.mainInfo}>
                        <Name>
                            {`${this.props.name}`}
                        </Name>
                        <div style={styles.elo}>
                            <span>
                                {`Elo | `}
                            </span>
                            <span>
                                {`${Math.floor(this.props.elo)}`}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render () {

        let { levelOption, salary, remoteFirst, publicOnly, location } = this.props.companyListState

        const tiers = [4, 20, 52, 100, 10000]

        const tierColors = ['255 86 86', '86 185 255', '255 171 86', '255 86 207', '134 86 255']
        const tierColorsHex = ['#ff5656', '#56b9ff', '#ffab56', '#ff56cf', '#8656ff']

        // 255, 86, 207 - 255, 86, 86 - 255, 171, 86 - 86, 185, 255 - 86, 255, 100

        let tier = 10

        for (let i = 0; i < tiers.length; i++) {
            if (Number(this.props.rank) <= tiers[i]) {
                tier = i + 1
                break
            }
        }

        // , border: `1px solid rgb(${tierColors[tier - 1]})`

        return (
            <div className={`bounce-up ${this.state.startAnimation ? 'in-view' : null}`}>
                <HoverStyle onClick={() => this.props.onClick()} style={{...styles.containerStyle, boxShadow: 'rgb(0, 0, 0, 0.15) 0px 2px 8px'}} key={this.props.index}>
                    <div style={{width: '100%', height: this.props.matched ? '10px' : '0', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', color: 'white', display: 'flex', fontWeight: '600', alignItems: 'center', justifyContent: 'center', backgroundColor: `rgb(${this.props.matched ? tierColors[tier - 1] : '255 255 255'})`}}>
                        {/*{`Tier ${tier}`}*/}
                    </div>
                    <div style={{display: 'inline-flex', alignItems: 'center', padding: '20px'}}>
                        {this.renderRankColumn()}
                        {this.renderCompanyColumn()}
                    </div>
                    <div style={{width: '100%', borderTop: '1px solid #CCC', alignItems: 'center', display: 'flex', justifyContent: 'space-between', padding: '20px', backgroundColor: 'rgba(0, 0, 0, 0.01)', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px'}}>
                        <div>
                            <div style={styles.infoTitle}>
                                {`${levelOption} TC`}
                            </div>
                            <div style={styles.infoInfo}>
                                {this.convertSalaryToString(this.props.salaryAtLevel)}
                            </div>
                        </div>
                        <div>
                            <div style={styles.infoTitle}>
                                Location
                            </div>
                            <div style={{...styles.infoInfo, fontWeight: '600', color: this.props.is_remote ? tierColorsHex[tier-1] : '#666'}}>
                                {this.props.is_remote ? `Remote` : 'In Office'}
                            </div>
                        </div>
                        <div>
                            <div style={styles.infoTitle}>
                                IPO
                            </div>
                            <div style={{...styles.infoInfo, fontWeight: '600', color: this.props.is_public ? tierColorsHex[tier-1] : '#666'}}>
                                {this.props.is_public ? `Public` : 'Private'}
                            </div>
                        </div>
                    </div>
                </HoverStyle>
            </div>
        )
    }
}

export default CompanyListItem
