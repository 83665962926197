import React from "react"
import Header from './Header'
import CompareBlock from './CompareBlock'
import Footer from "./Footer";

const styles = {
    compareContainer: {
        width: '100%',
        fontFamily: 'Lato',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
    },
    compareBlocks: {
        maxWidth: '1024px',
        width: '100%',
        display: 'flex',
        flexDirection: 'space-between',
    },
    title: {
        marginTop: 30,
        marginBottom: 10,
        fontWeight: '600',
        color: '#666',
        fontSize: '26px',
    },
}

class Compare extends React.Component {
    renderCompareBlock = company => <CompareBlock {...company} company_ids={this.props.companies.map(c => c.id)} />

    renderCompareBlocks = () => this.props.companies.map(this.renderCompareBlock)

    render () {
        return (
            <div style={styles.compareContainer}>
                <Header {...this.props} />
                <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, alignItems: 'center', justifyContent: 'center'}}>
                    <div style={styles.title}>
                        Choose the company you would prefer to work at!
                    </div>
                    <div style={{fontSize: '14px', color: '#999', fontWeight: '400', marginBottom: '50px'}}>
                        By voting you help determine which companies are the most prestigious
                    </div>
                    <div style={styles.compareBlocks}>
                        {this.renderCompareBlocks()}
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default Compare
