import React from "react"
import MoreCompanyItem from "./MoreCompanyItem"

const styles = {
    container: {
        backgroundColor: 'white',
        boxShadow: 'rgb(0, 0, 0, 0.15) 0px 2px 8px',
        width: '100%',
    },
    innerContainer: {
        padding: 20,
    },
}

class MoreCompanies extends React.Component {
    renderCompanyItem = company => (
        <MoreCompanyItem {...company} />
    )

    renderCompanyItems() {
        const {similar_companies} = this.props
        return similar_companies.map(this.renderCompanyItem)
    }

    render () {
        return (
            <div style={styles.container}>
                <div style={styles.innerContainer}>
                    {this.renderCompanyItems()}
                </div>
            </div>
        );
    }
}

export default MoreCompanies