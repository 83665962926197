import React from "react"
import InputText from './InputText'
import Button from './Button'
import StallionsApi from "../helpers/api"

const styles = {
    container: {
        backgroundColor: 'white',
        boxShadow: 'rgb(0, 0, 0, 0.15) 0px 2px 8px',
        padding: '25px',
    },
    formContainer: {
        marginTop: '20px',
    },
    title: {
        fontSize: '30px',
        color: '#333',
    },
    subtitle: {
        marginTop: '10px',
        fontSize: '18px',
        color: '#666',
    },
}

class LoginBox extends React.Component {
    state = {
        email: '',
        password: '',
    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value})
    }

    handleLogin = res => {
        if (res.data.success !== undefined && res.data.success === false) {
            // TODO: Show error
            console.log("Invalid credentials")
        } else {
            window.location.href = '/'
        }
    }

    onSubmit(e) {
        e.preventDefault()

        const { email, password } = this.state

        const query = {
            user: {
                email,
                password
            }
        }

        StallionsApi.fetch({
            method: "post",
            path: "/api/v0/users/login",
            query: query,
            callback: this.handleLogin
        })
    }

    render () {
        return (
            <div style={styles.container}>
                <form onSubmit={e => this.onSubmit(e)}>
                    <div>
                        <div style={styles.title}>
                            Log In
                        </div>
                        <div style={styles.subtitle}>
                            Welcome Back
                        </div>
                    </div>
                    <FormContainer>
                        <InputText
                            onChange={e => this.onChange(e)}
                            name="email"
                            placeholder="Email"
                            value={this.state.email}
                        />
                    </FormContainer>
                    <FormContainer>
                        <InputText
                            onChange={e => this.onChange(e)}
                            name="password"
                            type="password"
                            placeholder="Password"
                            value={this.state.password}
                        />
                    </FormContainer>
                    <div style={styles.formContainer}>
                        <Button type={'submit'} text={"Log In"} />
                    </div>
                </form>
            </div>
        );
    }
}

const FormContainer = ({children}) => (
    <div style={styles.formContainer}>
        {children}
    </div>
)

export default LoginBox
