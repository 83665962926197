import axios from 'axios'

export default class StallionsApi {
    constructor() {

    }

    static fetch = ({method = "get", path, query = {}, callback, errorCallback}) => {
        query.authenticity_token = Array.prototype.slice.call(document.getElementsByTagName('meta')).filter(item => item.name === 'csrf-token')[0].content
        axios[method](`${document.location.origin}${path}`, query)
            .then(res => {
                callback && callback(res)
            })
            .catch(err => {
                errorCallback && errorCallback(err)
            })
    }
}