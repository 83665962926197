import React from "react"
import MoreCompanies from "./MoreCompanies"

const styles = {
    headerContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    container: {
        backgroundColor: 'white',
        boxShadow: 'rgb(0, 0, 0, 0.15) 0px 2px 8px',
        width: '100%',
    },
    mainContent: {
        width: '100%',
    },
    sideContent: {
        marginLeft: '30px',
        width: '300px',
    },
    innerContainer: {
        padding: 20,
    },
    titleSection: {
        marginTop: 20,
        marginBottom: 20,
        display: 'inline-flex',
        alignItems: 'center',
    },
    name: {
        fontWeight: '600',
        fontSize: '28px',
        marginLeft: '10px',
    },
    miniTitle: {
        fontSize: '14px',
        color: '#666',
    },
    stat: {
        fontWeight: '600',
        fontSize: '24px',
    },
    infoRow: {
        display: 'flex',
    },
    infoSection: {
        marginLeft: 40,
    },
}

class CompanyHeader extends React.Component {
    render () {
        const { company } = this.props
        const logoUrl = company.logo_url ? company.logo_url : `//logo.clearbit.com/${company.link}`
        return (
            <div>
                <a target="_blank" href={company.link}>
                    <div style={styles.titleSection}>
                        <img height="50px" src={logoUrl} />
                        <div style={styles.name}>
                            {company.name}
                        </div>
                    </div>
                </a>
                <div style={styles.headerContainer}>
                    <div style={styles.mainContent}>
                        <div style={styles.container}>
                            <div style={styles.innerContainer}>
                                <div style={styles.infoRow}>
                                    <div>
                                        <div style={styles.miniTitle}>
                                            Elo
                                        </div>
                                        <div style={styles.stat}>
                                            {Math.floor(company.elo)}
                                        </div>
                                    </div>
                                    <div style={styles.infoSection}>
                                        <div style={styles.miniTitle}>
                                            Rank
                                        </div>
                                        <div style={styles.stat}>
                                            {company.rank}
                                        </div>
                                    </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                    <div style={styles.sideContent}>
                        <MoreCompanies {...this.props} />
                    </div>
                </div>
            </div>
        );
    }
}

export default CompanyHeader