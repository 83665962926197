import React, { useState, useRef } from 'react';

const FileUpload = ({ uploadedResume, presignedUrl, uploadCallback, theFile }) => {
    const [file, setFile] = useState(theFile);
    const [uploading, setUploading] = useState(false);
    const [uploaded, setUploaded] = useState(uploadedResume?.uploaded || false)

    const handleDrop = e => {
        e.preventDefault();

        if (e.dataTransfer.files.length > 0) {
            setFile(e.dataTransfer.files[0]);
            handleUpload(e.dataTransfer.files[0])
        }
    };

    const handleUpload = async (theFile) => {
        if (!theFile) return;

        setUploading(true);

        try {
            const res = await fetch(presignedUrl, {
                method: 'PUT',
                body: theFile
            });

            if (res.status !== 200) {
                throw new Error('Failed to upload image');
            }

            uploadCallback(res, theFile)
            setUploaded(true)
            setUploading(false);
        } catch (err) {
            setUploading(false);
        }
    };

    const renderResumeScreen = () => {
        if (uploading && file) {
            return (
                <div style={{minHeight: '400px', color: '#666', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    {`${file.name} is uploading...`}
                </div>
            )
        }
        if (uploaded) {
            return (
                <div style={{minHeight: '400px', color: '#666', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <div style={{display: 'flex'}}>
                            <i style={{fontSize: '20px', color: '#ff5656', marginRight: '10px'}} className="fas fa-check-circle" />
                            {`${uploadedResume?.name || file?.name} Uploaded!`}
                        </div>
                        <div style={{marginTop: '20px'}}>
                            If you would like to replace your resume, drag & drop your new one here
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div style={{minHeight: '400px', color: '#666', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                Drag and drop your resume here, or click to select your resume to upload
            </div>
        )
    }

    const hiddenFileInput = React.useRef(null)

    const handleChange = event => {
        const fileUploaded = event.target.files[0]
        setFile(fileUploaded)
        handleUpload(fileUploaded)
    }

    const handleClick = event => {
        hiddenFileInput.current.click();
    }

    return (
        <>
            <div
                type={"file"}
                style={{
                    border: '1px solid #ddd',
                    padding: 16,
                    textAlign: 'center',
                    cursor: 'pointer'
                }}
                onDragOver={e => e.preventDefault()}
                onDrop={handleDrop}
                onClick={handleClick}
            >
                {renderResumeScreen()}
            </div>
            <input
                type="file"
                ref={hiddenFileInput}
                onChange={handleChange}
                style={{display: 'none'}}
            />
        </>
    );
};

export default FileUpload
