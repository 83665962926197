import React from "react"
import CompanyListItem from './CompanyListItem'
import Checkbox from "./Checkbox";
import utils from './../helpers/utils'
import styled from "styled-components";
import Dropdown from "./Dropdown";
import Header from "./Header";
import FileUpload from "./FIleUpload";
import AutoApplySetup from "./AutoApplySetup";
import {GoogleOAuthProvider, useGoogleLogin} from "@react-oauth/google";
import CompanyDetails from "./CompanyDetails";

const moment = require('moment');

const styles = {
    companyListContainer: {
        justifyContent: 'center',
        maxWidth: '1600px',
        margin: '0 auto',
        display: 'flex',
        flexWrap: 'wrap',
        gap: '50px',
        width: '100%',
        paddingBottom: '60px'
    },
    tableRow: {
        height: '45px',
        border: 'none',
    },
    tableColumn: {
        height: 'inherit',
        padding: '0 15px',
        color: '#999',
    },
    centeredColumn: {
        height: 'inherit',
        padding: '0 15px',
        textAlign: 'center',
        color: '#999',
    },
    mobileContainer: {
      maxWidth: '768px',
      overflowX: 'scroll',
    },
    input: {
        fontWeight: '500',
        padding: '10px',
        width: '100%',
        color: '#333',
        borderRadius: '10px',
        border: '1px solid #AAA',
        fontSize: '16px'
    },
    levelButton: {
        border: '1px solid #CCC',
        color: '#666',
        width: '100px',
        textAlign: 'center',
        fontSize: '14px',
        padding: '10px',
        borderRadius: '20px',
        cursor: 'pointer'
    },
    notificationTitle: {
        fontWeight: '500',
        fontSize: '16px',
        color: '#333',
        marginLeft: '10px',
    },
    offerings: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        padding: '10px',
        textAlign: 'center',
        fontWeight: '400',
        color: '#444',
        fontSize: '18px'
    },
    modalContainer: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        position: 'fixed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 2
    },
    modalBackground: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        position: 'fixed',
        zIndex: 100,
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    modalForegroundContainer: {
        position: 'relative',
        maxWidth: '100%',
        width: '1200px',
        padding: '0 10px',
        height: '80vh',
        zIndex: 500,
    },
    modalForeground: {
        backgroundColor: 'white',
        maxWidth: '100%',
        width: '1200px',
        borderRadius: '20px',
        height: '80vh',
        overflowY: 'scroll',
        padding: '30px',
        objectFit: 'contain',
    },
    name: {
        fontWeight: '500',
        fontSize: '24px',
    },
    companyInfoTitle: {
        fontSize: '12px',
        color: '#999',
    },
    companyInfoDescription: {
        fontWeight: '500',
        color: '#666'
    },
    companyInfoSection: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'right',
        alignItems: 'flex-end',
    },
    primaryButton: {
        backgroundColor: '#ff5656',
        color: 'white',
        padding: '10px 20px',
        borderRadius: '20px',
        fontWeight: '600',
        textAlign: 'center',
        cursor: 'pointer',
    },
    secondaryButton: {
        border: '1px solid #CCC',
        padding: '10px 20px',
        borderRadius: '20px',
        cursor: 'pointer',
        color: '#555',
    },
    emptyButton: {
        userSelect: 'none',
        padding: '10px 20px',
        borderRadius: '20px',
        width: '250px',
        textAlign: 'center',
        border: '1px solid #CCC',
        color: '#666',
        cursor: 'not-allowed'
    },
}

const SORT_VARS = {
    rank: {
        value: 'rank',
        defaultOrder: 'asc',
        compareType: 'number',
    },
    name: {
        value: 'name',
        defaultOrder: 'asc',
        compareType: 'string',
    },
    salary_entry_amount: {
        value: 'salary_entry_amount',
        defaultOrder: 'desc',
        compareType: 'number',
    },
    salary_midlevel_amount: {
        value: 'salary_midlevel_amount',
        defaultOrder: 'desc',
        compareType: 'number',
    },
    salary_senior_amount: {
        value: 'salary_entry_amount',
        defaultOrder: 'desc',
        compareType: 'number',
    }
}

const LevelButton = styled.div`
:hover {
    background-color: #EEE !important;
}
`

const PrimaryButton = styled.div`
transition: 0.2s;
:hover {
    background-color: #ff7171 !important;
}
`

const SecondaryButton = styled.div`
transition: 0.2s;
:hover {
    background-color: #333 !important;
    color: #FFF !important;
    border: 1px solid #333 !important;
}
`

let Signup = (props) => {
    const googleLogin = useGoogleLogin({
        scope: "email profile",
        onSuccess: async tokenResponse => {
            let body = {
                access_token: tokenResponse.access_token
            }
            const token = document.querySelector('[name=csrf-token]').content
            const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}

            fetch(`${window.location.origin}/api/v0/auths`, {
                method: "POST",
                headers: headers,
                body: JSON.stringify(body),
            }).then(res => res.json())
                .then(res => {
                    if (res.success) {
                        props.setUser && props.setUser(res.user)
                    } else {

                    }
                })
                .catch(err => {

                })
        },
    });

    return (
        <div onClick={() => googleLogin()}>
            {props.children}
        </div>
    )
}

class CompanyList extends React.Component {

    constructor(props) {
        super(props)

        let companies = props.companies
        for (let i = 0; i < companies.length; i++) {
            companies[i].rank = i + 1;
        }

        this.state = {
            levelOption: localStorage.getItem('levelOption') || 'Mid',
            remoteFirst: JSON.parse(localStorage.getItem('remoteFirst')) || false,
            publicOnly: JSON.parse(localStorage.getItem('publicOnly')) || false,
            salary: localStorage.getItem('salary') || '',
            user: props.user,
            location: localStorage.getItem('location') || 'Anywhere',
            sortType: 'rank',
            sortOrder: 'asc',
            modalPage: 'company',
            autoApplyPage: 1,
            showModal: false,
            company: null,
            problem: "",
            companies: companies,
            interview_level: 'Select Level',
            interview_type: 'Select Type',
            interview_name: 'Select Interview',
            role: 'Select Role',
            filter_interview_level: 'Select Level',
            filter_interview_type: 'Select Type',
            filter_interview_name: 'Select Interview',
            filter_role: 'Select Role',
            mobile: window.innerWidth < 1000
        }
    }

    windowSizeChange = () => {
        this.setState({mobile: window.innerWidth < 1000})
    }

    componentDidMount() {
        window.addEventListener("resize", this.windowSizeChange.bind(this));
    }

    close = () => {
        this.setState({showModal: false})
    }

    setUser = (user) => {
        this.setState({user})
    }

    setUserThenAutoApply = (user) => {
        let parentThis = this
        this.setState({user}, () => {
            parentThis.loadSetupAutoApply()
        })
    }

    renderCompanyPage = () => {
        return <CompanyDetails company={this.state.company} mobile={this.state.mobile} user={this.state.user} setUser={this.setUser.bind(this)} />
    }

    hideModal = () => {
        this.setState({showModal: false})
        window.location.href = "/dashboard"
    }

    renderModal = () => {
        if (!this.state.showModal) {
            return null
        }

        if (this.state.modalPage === 'company') {
            return (
                <div style={styles.modalContainer}>
                    <div style={styles.modalForegroundContainer}>
                        <div style={{...styles.modalForeground, padding: this.state.mobile ? '20px' : '30px'}}>
                            {this.renderCompanyPage()}
                        </div>
                    </div>
                    <div onClick={this.close} style={styles.modalBackground}/>
                </div>
            )
        }

        if (this.state.modalPage === 'setupAutoApply') {
            return (
                <div style={styles.modalContainer}>
                    <div style={styles.modalForegroundContainer}>
                        <div style={{...styles.modalForeground, padding: this.state.mobile ? '20px' : '30px'}}>
                            <AutoApplySetup hideModal={this.hideModal.bind(this)} remoteFirst={this.state.remoteFirst} publicOnly={this.state.publicOnly} location={this.state.location} levelOption={this.state.levelOption} salary={this.state.salary} {...this.props} currentUser={this.state.user} />
                        </div>
                    </div>
                    <div onClick={this.close} style={styles.modalBackground}/>
                </div>
            )
        }
    }

    loadCompanyPage = (company) => {
        this.setState({showModal: true, company, modalPage: 'company'})
    }

    loadSetupAutoApply = () => {
        this.setState({showModal: true, modalPage: 'setupAutoApply'})
    }

    renderCompanyListItem = (company, matched, last, index) => <CompanyListItem  onClick={() => this.loadCompanyPage(company)} {...company} matched={matched} companyListState={this.state} user={this.state.user} last={last} index={index} />

    renderCompanies = () => {
        let { levelOption, salary, remoteFirst, publicOnly, location } = this.state

        salary = Number(salary.replace(/\D/g,'')) || 0

        let matchedCompanies = []
        let unmatchedCompanies = []

        for (let i = 0; i < this.props.companies.length; i++) {
            let company = this.props.companies[i]
            let salaryAtLevel = null
            let isMatched = true

            if (location !== 'Anywhere') {
                if (!company.locations.includes(location) && !company.is_remote) {
                    isMatched = false
                }
            }

            if (levelOption === 'Entry') {
                salaryAtLevel = company.salary_entry_amount
                if (company.salary_entry_amount < salary) {
                    isMatched = false
                }
            }

            if (levelOption === 'Mid') {
                salaryAtLevel = company.salary_midlevel_amount
                if (company.salary_midlevel_amount < salary) {
                    isMatched = false
                }
            }

            if (levelOption === 'Senior') {
                salaryAtLevel = company.salary_senior_amount
                if (company.salary_senior_amount < salary) {
                    isMatched = false
                }
            }

            if (levelOption === 'Staff') {
                salaryAtLevel = company.salary_staff_amount
                if (company.salary_staff_amount < salary) {
                    isMatched = false
                }
            }

            if (levelOption === 'Sr. Staff') {
                salaryAtLevel = company.salary_senior_staff_amount
                if (company.salary_senior_staff_amount < salary) {
                    isMatched = false
                }
            }

            company.salaryAtLevel = salaryAtLevel

            if (publicOnly && !company.is_public) {
                isMatched = false
            }

            if (remoteFirst && !company.is_remote) {
                isMatched = false
            }

            if (isMatched) {
                matchedCompanies.push(company)
            } else {
                unmatchedCompanies.push(company)
            }
        }

        return (
            <div style={{width: '100%'}}>
                <div style={{justifyContent: 'center', display: 'flex', flexWrap: 'wrap', gap: '50px', width: '100%'}}>
                    {matchedCompanies.map((company, index) => this.renderCompanyListItem(company, true, (index + 1) === this.props.companies.length, index))}
                </div>
                <div style={{width: '100%', display: unmatchedCompanies.length > 0 ? 'flex' : 'none', flexDirection: 'column', alignItems: 'center', gap: '10px', margin: '40px 0'}}>
                    <div style={{width: '100%', height: '1px', backgroundColor: '#CCC'}} />
                    <div style={{fontWeight: '600', color: '#555', fontSize: '24px', marginTop: '20px'}}>
                        Other Companies
                    </div>
                    <div style={{fontWeight: '400', color: '#999', fontSize: '14px'}}>
                        These are companies that did not match your search criteria
                    </div>
                </div>
                <div style={{justifyContent: 'center', display: 'flex', flexWrap: 'wrap', gap: '50px', width: '100%'}}>
                    {unmatchedCompanies.map((company, index) => this.renderCompanyListItem(company, false, (index + 1) === this.props.companies.length, index))}
                </div>
            </div>
        )
    }

    convertAnythingToMoney = (str) => {
        let hasDollar = false
        if (str[0] === '$') {
            hasDollar = true
            str = str.substr(1)
        }
        if (str[0] === '.') {
            str = '0' + str
        }

        let foundDecimal = false
        let afterDecimal = 0
        const numbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', ',']

        for (let i = 0; i < str.length; i++) {
            if (numbers.includes(str[i])) {
                if (foundDecimal) {
                    afterDecimal++
                    if (afterDecimal === 3) {
                        return false
                    }
                }
            } else if (str[i] === '.') {
                if (foundDecimal) {
                    return false
                }
                foundDecimal = true
            } else {
                return false
            }
        }

        let endIndex = str.indexOf('.')
        if (endIndex === -1) {
            endIndex = str.length
        }

        if (str.length === 0) {
            if (hasDollar) {
                return '$'
            }
            return ''
        }

        str = Number(str.substr(0, endIndex).replace(/,/g,'')).toLocaleString("en-US") + str.substr(endIndex)

        return '$' + str
    }

    onMoneyChange = (e) => {
        const res = this.convertAnythingToMoney(e.target.value)
        localStorage.setItem('salary', res)
        if (res !== false) {
            this.setState({[e.target.name]: res})
        }
    }

    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value})
    }

    updateVisible = (publicOnly) => {
        localStorage.setItem('publicOnly', publicOnly)
        this.setState({publicOnly})
    }

    updateRemote = (remoteFirst) => {
        localStorage.setItem('remoteFirst', remoteFirst)
        this.setState({remoteFirst})
    }

    renderLevelOption = (levelOption) => {
        const selected = levelOption === this.state.levelOption
        const backgroundColor = selected ? '#333' : null
        const border = selected ? '1px solid #333' : '1px solid #CCC'
        const color = selected ? '#FFF' : '#999'

        if (selected) {
            return (
                <div style={{...styles.levelButton, backgroundColor, border, color }}>
                    {levelOption}
                </div>
            )
        }

        let setLevelOption = (levelOption) => {
            localStorage.setItem('levelOption', levelOption)
            this.setState({levelOption})
        }

        return (
            <LevelButton onClick={setLevelOption.bind(this, levelOption)} style={{...styles.levelButton, backgroundColor, border, color }}>
                {levelOption}
            </LevelButton>
        )
    }

    renderLevelOptions = () => {
        const levelOptions = ["Entry", "Mid", "Senior", "Staff", "Sr. Staff"]
        return levelOptions.map(this.renderLevelOption)
    }

    renderDesktop = () => {
        let isTesting = window.location.host === "localhost:3000"

        let googleClientKey = "550406215467-i8kvabgk3dk9nh96l82jukkhijai3fe6.apps.googleusercontent.com"

        if (isTesting) {
            googleClientKey = "140207125595-29ih2v5auomi618v0p3tvi70dmami1nr.apps.googleusercontent.com"
        }

        const setLocation = (location) => {
            localStorage.setItem('location', location)
            this.setState({location})
        }

        return(
            <GoogleOAuthProvider clientId={googleClientKey}>
                <Header setUser={this.setUser} search={(company) => this.loadCompanyPage(company)} {...this.props} currentUser={this.state.user} />
                <div style={{width: '100%'}}>
                    <div style={{width: '100%', padding: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap', gap: this.state.mobile ? '20px' : '60px'}}>
                        <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                            <div style={{fontWeight: '500', fontSize: '16px', textAlign: this.state.mobile ? 'center' : 'left'}}>
                                Select Level
                            </div>
                            <div style={{display: 'flex', flexWrap: 'wrap', gap: '20px', justifyContent: this.state.mobile ? 'center' : 'flex-start'}}>
                                {this.renderLevelOptions()}
                            </div>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                            <div style={{fontWeight: '500', fontSize: '16px'}}>
                                Minimum TC
                            </div>
                            <input style={{...styles.input, maxWidth: this.state.mobile ? '120px' : '300px'}} name={"salary"} placeholder={"$0"} value={this.state.salary} onChange={this.onMoneyChange} />
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                            <div style={{fontWeight: '500', fontSize: '16px'}}>
                                Location
                            </div>
                            <Dropdown options={["Anywhere", "San Francisco, CA", "New York, NY", "Seattle, WA"]} selected={this.state.location} onSelect={(location) => setLocation.call(this, location)} />
                        </div>
                        <div style={{display: 'flex', flexDirection: this.state.mobile ? 'row' : 'column', gap: '20px', flexWrap: this.state.mobile ? 'wrap' : 'nowrap'}}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <Checkbox active={this.state.publicOnly} updateFn={this.updateVisible.bind(this)} model={'something'} field={'publicOnly'} />
                                <div style={styles.notificationTitle}>
                                    Public Only
                                </div>
                            </div>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <Checkbox active={this.state.remoteFirst} updateFn={this.updateRemote.bind(this)} model={'something'} field={'remoteFirst'} />
                                <div style={styles.notificationTitle}>
                                    Remote
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        this.state.user ? (
                            <div onClick={() => this.loadSetupAutoApply()} style={{cursor: 'pointer', padding: '10px 20px', maxWidth: '1200px', flexWrap: 'wrap', width: '100%', margin: '0 auto 30px', backgroundColor: 'rgba(146, 146, 146, 0.1)', borderRadius: this.state.mobile ? '0px' : '20px', display: 'flex', justifyContent: this.state.mobile ? 'center' : 'space-between', alignItems: 'center', gap: '20px'}}>
                                <div style={{display: 'flex', gap: '20px', alignItems: 'center'}}>
                                    <i style={{fontSize: '32px', color: '#333'}} className="fas fa-clipboard-check" />
                                    <div style={{fontWeight: '500', color: '#333', fontSize: '22px'}}>
                                        Auto Apply
                                    </div>
                                    <div style={{fontWeight: '400', color: '#666', fontSize: '16px', maxWidth: '500px'}}>
                                        Select companies & we will apply on your behalf!
                                    </div>
                                </div>
                                <div style={{...styles.primaryButton, display: 'inline', fontSize: '20px', padding: '10px 30px', width: '250px', borderRadius: '10px', backgroundColor: '#333'}}>
                                    Setup Auto Apply
                                </div>
                            </div>
                        ) : (

                                <Signup setUser={this.setUserThenAutoApply}>
                                    <div style={{cursor: 'pointer', padding: '10px 20px', maxWidth: '1200px', flexWrap: 'wrap', width: '100%', margin: '0 auto 30px', backgroundColor: 'rgba(146, 146, 146, 0.1)', borderRadius: this.state.mobile ? '0px' : '20px', display: 'flex', justifyContent: this.state.mobile ? 'center' : 'space-between', alignItems: 'center', gap: '20px'}}>
                                        <div style={{display: 'flex', gap: '20px', alignItems: 'center'}}>
                                            <i style={{fontSize: '32px', color: '#333'}} className="fas fa-clipboard-check" />
                                            <div style={{fontWeight: '500', color: '#333', fontSize: '22px'}}>
                                                Auto Apply
                                            </div>
                                            <div style={{fontWeight: '400', color: '#666', fontSize: '16px', maxWidth: '500px'}}>
                                                Select companies & we will apply on your behalf!
                                            </div>
                                        </div>
                                        <div style={{...styles.primaryButton, display: 'inline', fontSize: '20px', padding: '10px 30px', width: '250px', borderRadius: '10px', backgroundColor: '#333'}}>
                                            Setup Auto Apply
                                        </div>
                                    </div>
                                </Signup>

                        )
                    }

                    {/*<div style={{marginTop: '30px', width: '100%', textAlign: 'center', fontWeight: '700', color: '#333', fontSize: '24px'}}>*/}
                    {/*    Auto Apply*/}
                    {/*</div>*/}
                    {/*<div style={{marginTop: '10px', padding: '0 20px', width: '100%', textAlign: 'center', fontWeight: '400', color: '#666', fontSize: '16px'}}>*/}
                    {/*    Select companies you are interested in & we will apply to relevant roles directly via the company's career page on your behalf.*/}
                    {/*</div>*/}
                    {/*<div style={{marginTop: '20px', marginBottom: '20px', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>*/}
                    {/*    <div style={{display: 'flex', gap: '20px', flexWrap: 'wrap', justifyContent: 'center'}}>*/}
                    {/*        <a href={"/dashboard"}>*/}
                    {/*            <div style={{fontSize: '20px', padding: '10px 30px', borderRadius: '10px', width: '250px', textAlign: 'center', border: '1px solid #CCC', color: '#666', cursor: 'pointer'}}>*/}
                    {/*                Dashboard*/}
                    {/*            </div>*/}
                    {/*        </a>*/}
                    {/*        <div onClick={() => this.loadSetupAutoApply()} style={{...styles.primaryButton, display: 'inline', fontSize: '20px', padding: '10px 30px', width: '250px', borderRadius: '10px'}}>*/}
                    {/*            {`Setup Auto Apply`}*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*{ !this.state.mobile && (*/}
                    {/*    <div style={{display: 'flex', flexWrap: 'wrap', width: '100%', justifyContent: 'center', gap: '30px', margin: '20px 0 0'}}>*/}
                    {/*        <div style={styles.offerings}>*/}
                    {/*            <i style={{fontSize: '20px', color: '#56b9ff'}} className="fas fa-check-circle" />*/}
                    {/*            Real Interview Questions*/}
                    {/*        </div>*/}
                    {/*        <div style={styles.offerings}>*/}
                    {/*            <i style={{fontSize: '20px', color: '#56b9ff'}} className="fas fa-check-circle" />*/}
                    {/*            Search by Comp*/}
                    {/*        </div>*/}
                    {/*        <div style={styles.offerings}>*/}
                    {/*            <i style={{fontSize: '20px', color: '#56b9ff'}} className="fas fa-check-circle" />*/}
                    {/*            Auto Apply*/}
                    {/*        </div>*/}
                    {/*        <div style={styles.offerings}>*/}
                    {/*            <i style={{fontSize: '20px', color: '#56b9ff'}} className="fas fa-check-circle" />*/}
                    {/*            Prestige Rankings*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    )}*/}
                    <div style={{...styles.companyListContainer, marginTop: '20px'}}>
                        {this.renderCompanies()}
                    </div>
                    {this.renderModal()}
                </div>
            </GoogleOAuthProvider>
    )}

    render () {
        return this.renderDesktop()
    }
}

export default CompanyList
