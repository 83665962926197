import React from "react"
import Header from './Header'
import CompanyList from './CompanyList'
import AccountCreationBanner from "./AccountCreationBanner"
import Footer from './Footer'

const styles = {
    homeContainer: {
        width: '100%',
        fontFamily: 'Lato',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    companyListHolder: {
        padding: '0 0 20px',
        width: '100%',
    },
}

class Home extends React.Component {

    render () {
        return (
            <div style={styles.homeContainer}>
                <CompanyList {...this.props} />
                <Footer />
            </div>
        );
    }
}

export default Home
