import React from 'react'
import CompanyDetails from "./CompanyDetails"
import Header from "./Header";
import Footer from "./Footer";
import {GoogleOAuthProvider} from "@react-oauth/google"

const styles = {
    container: {
        width: '100%',
        fontFamily: 'lato, arial'
    }
}

class CompanyPage extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            mobile: window.innerWidth < 1000,
            user: props.user
        }
    }

    componentDidMount() {
        window.addEventListener("resize", this.windowSizeChange.bind(this));
    }

    windowSizeChange = () => {
        this.setState({mobile: window.innerWidth < 1000})
    }

    setUser = (user) => {
        this.setState({user})
    }

    render() {
        let isTesting = window.location.host === "localhost:3000"

        let googleClientKey = "550406215467-i8kvabgk3dk9nh96l82jukkhijai3fe6.apps.googleusercontent.com"

        if (isTesting) {
            googleClientKey = "140207125595-29ih2v5auomi618v0p3tvi70dmami1nr.apps.googleusercontent.com"
        }

        return (
            <GoogleOAuthProvider clientId={googleClientKey} setUser={this.setUser} currentUser={this.state.user}>
                <div style={styles.container}>
                    <Header setUser={this.setUser} {...this.props} currentUser={this.state.user} />
                    <div style={{margin: '0 auto 40px', maxWidth: '1600px', padding: '0 20px'}}>
                        <CompanyDetails mobile={this.state.mobile} company={this.props.company} user={this.state.user} setUser={this.setUser.bind(this)} />
                    </div>
                    <Footer />
                </div>
            </GoogleOAuthProvider>
        )
    }
}

export default CompanyPage