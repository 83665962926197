import React from "react"
import Header from './Header'
import Footer from "./Footer"
import Checkbox from "./Checkbox";
import AutoApplySetup from "./AutoApplySetup";
import styled from "styled-components";

const styles = {
    homeContainer: {
        width: '100%',
        fontFamily: 'Lato',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    termsContainer: {
        maxWidth: '1600px',
        width: '100%',
        margin: '0 0 20px 0',
        display: 'flex',
        flexDirection: 'column',
    },
    primaryButton: {
        backgroundColor: '#ff5656',
        color: 'white',
        padding: '10px 20px',
        borderRadius: '20px',
        fontWeight: '600',
        textAlign: 'center',
        cursor: 'pointer',
    },
    secondaryButton: {
        border: '1px solid #CCC',
        padding: '10px 20px',
        borderRadius: '20px',
        cursor: 'pointer',
        color: '#555',
    },
    info: {
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
        width: '300px'
    },
    infoTitle: {
        fontSize: '14px',
        color: '#999'
    },
    infoInfo: {
        padding: '10px 20px',
        color: '#333',
        fontSize: '16px',
        border: '1px solid #CCC',
        borderRadius: '10px',
    },
    input: {
        fontWeight: '500',
        padding: '10px',
        width: '300px',
        color: '#333',
        borderRadius: '10px',
        border: '1px solid #AAA',
        fontSize: '16px'
    },
    infoBox: {
        padding: '20px',
        border: '1px solid #CCC',
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
        marginBottom: '10px',
    },
    infoBoxWithAction: {
        padding: '20px',
        border: '1px solid #CCC',
        borderRadius: '10px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '10px',
    },
    settingTitle: {
        color: '#999'
    },
    settingInfo: {
        color: '#555',
        fontWeight: '600',
        fontSize: '18px'
    },
    modalContainer: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        position: 'fixed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 2
    },
    modalBackground: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        position: 'fixed',
        zIndex: 100,
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    modalForegroundContainer: {
        position: 'relative',
        maxWidth: '100%',
        padding: '0 10px',
        width: '1200px',
        height: '80vh',
        zIndex: 500,
    },
    modalForeground: {
        backgroundColor: 'white',
        maxWidth: '100%',
        width: '1200px',
        borderRadius: '20px',
        height: '80vh',
        overflowY: 'scroll',
        padding: '30px',
        objectFit: 'contain',
    },
}

class Application extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            link: ""
        }
    }

    renderText = () => {
        const {status} = this.props.application

        if (status === "pending") {
            return (
                <div style={{color: '#999', whiteSpace: 'nowrap', overflow: "hidden", width: "200px", textOverflow: 'ellipsis', fontWeight: '400'}}>
                    In Queue
                </div>
            )
        }
        if (status === "success") {
            return (
                <div style={{color: '#ff5656', whiteSpace: 'nowrap', overflow: "hidden", width: "200px", textOverflow: 'ellipsis', fontWeight: '600'}}>
                    <a href={application.job_listing} target={"_blank"}>
                        {application.job_listing}
                    </a>
                </div>
            )
        }
        if (status === "failed") {
            return (
                <div style={{color: '#333', whiteSpace: 'nowrap', overflow: "hidden", width: "200px", textOverflow: 'ellipsis', fontWeight: '400'}}>
                    No relevant roles
                </div>
            )
        }
    }

    renderIcon = () => {
        const {status} = this.props.application

        if (status === "pending") {
            return (
                <div style={{backgroundColor: '#FFF', display: 'flex', alignItems: 'center', gap: '5px', padding: '10px', border: '1px solid #CCC', borderRadius: '10px'}}>
                    <i style={{fontSize: '20px', color: '#CCC'}} className="fas fa-minus-circle" />
                    <div style={{fontWeight: '600', color:' #666'}}>
                        Pending
                    </div>
                </div>
            )
        }
        if (status === "success") {
            return (
                <div style={{backgroundColor: '#FFF', display: 'flex', alignItems: 'center', gap: '5px', padding: '10px', border: '1px solid #CCC', borderRadius: '10px'}}>
                    <i style={{fontSize: '20px', color: '#ff5656'}} className="fas fa-check-circle" />
                    <div style={{fontWeight: '600', color:' #666'}}>
                        Applied
                    </div>
                </div>
            )
        }
        if (status === "failed") {
            return (
                <div style={{backgroundColor: '#FFF', display: 'flex', alignItems: 'center', gap: '5px', padding: '10px', border: '1px solid #CCC', borderRadius: '10px'}}>
                    <i style={{fontSize: '20px', color: '#333'}} className="fas fa-times-circle" />
                    <div style={{fontWeight: '600', color:' #666'}}>
                        No Role
                    </div>
                </div>
            )
        }
    }

    render() {
        const {company} = this.props
        const logoUrl = company.logo_url ? company.logo_url : `//logo.clearbit.com/${company.link}`

        return (
            <div style={{backgroundColor: this.props.odd ? '#F6F6F6' : '#FFF', maxWidth: '550px', width: '100%', flexWrap: 'wrap', padding: '10px 10px', display: 'flex', gap: '10px', alignItems: 'center', justifyContent: 'space-between'}}>
                <img width="45px" height="45px" style={{objectFit: 'contain', borderRadius: company.rounded ? '50%' : '0%'}} src={logoUrl}/>
                <div style={{width: '100px', fontWeight: '600', color: '#333'}}>
                    {company.name}
                </div>
                {this.renderText()}
                {this.renderIcon()}
            </div>
        )
    }
}

const PrimaryButton = styled.div`
transition: 0.2s;
:hover {
    background-color: #ff7171 !important;
}
`

const SecondaryButton = styled.div`
transition: 0.2s;
:hover {
    background-color: #333 !important;
    color: #FFF !important;
    border: 1px solid #333 !important;
}
`

class Dashboard extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            showModal: false,
            modalPage: 'setupAutoApply',
            levelOption: localStorage.getItem('levelOption') || 'Mid',
            remoteFirst: JSON.parse(localStorage.getItem('remoteFirst')) || false,
            publicOnly: JSON.parse(localStorage.getItem('publicOnly')) || false,
            salary: localStorage.getItem('salary') || '',
            user: props.user,
            location: localStorage.getItem('location') || 'Anywhere',
        }
    }

    close = () => {
        this.setState({showModal: false})
    }

    renderApplication = (application, index) => {
        let company = this.props.companies.find((company) => company.id === application.company_id)
        return <Application key={application.id} application={application} company={company} odd={index % 2 === 1}/>
    }

    hideModal = () => {
        this.setState({showModal: false})
    }

    renderModal = () => {
        if (!this.state.showModal) {
            return null
        }

        if (this.state.modalPage === 'setupAutoApply') {
            return (
                <div style={styles.modalContainer}>
                    <div style={styles.modalForegroundContainer}>
                        <div style={{...styles.modalForeground, padding: this.state.mobile ? '20px' : '30px'}}>
                            <AutoApplySetup hideModal={this.hideModal.bind(this)} remoteFirst={this.state.remoteFirst} publicOnly={this.state.publicOnly} location={this.state.location} levelOption={this.state.levelOption} salary={this.state.salary} {...this.props} currentUser={this.state.user} />
                        </div>
                    </div>
                    <div onClick={this.close} style={styles.modalBackground}/>
                </div>
            )
        }
    }

    updateAutoApply = (auto_apply_on) => {
        const token = document.querySelector('[name=csrf-token]').content
        const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}

        const body = {
            user: {
                auto_apply_on,
            }
        }

        let parentThis = this

        fetch(`${window.location.origin}/api/v0/users/${this.state.user?.id}`, {
            method: "PATCH",
            headers: headers,
            body: JSON.stringify(body),
        }).then(res => res.json())
            .then(res => {
                if (res.success) {
                    this.setState({user: res.user})
                }
            })
            .catch(err => {
            })
    }

    render () {

        let applicationsSubmitted = 0


        let companySet = new Set()

        for (let i = 0; i < this.props.applications.length; i++) {
            let application = this.props.applications[i]

            if (application.status === "success") {
                applicationsSubmitted += 1
                companySet.add(application.company_id)
            }
        }

        let companiesAppliedTo = companySet.size

        return (
            <div style={styles.homeContainer}>
                <Header {...this.props} />
                <div style={{height: 20, width: '100%'}} />
                <div style={styles.termsContainer}>
                    <div style={{alignSelf: 'center', fontWeight: '600', color: '#333', fontSize: '28px'}}>
                        {this.state.user.auto_apply_on ? 'Manage Auto Apply' : `Setup Auto Apply`}
                    </div>
                    <div style={{marginTop: '10px', alignSelf: 'center', fontSize: '14px', color: '#999', fontWeight: '400'}}>
                        Setup or manage your auto applying!
                    </div>
                    <div style={{marginTop: '20px', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <div style={{display: 'flex', gap: '20px', flexWrap: 'wrap', justifyContent: 'center'}}>
                            <a href={"/premium"}>
                                <SecondaryButton style={{fontSize: '20px', padding: '10px 30px', borderRadius: '10px', width: '250px', textAlign: 'center', border: '1px solid #CCC', color: '#666', cursor: 'pointer'}}>
                                    Get Premium
                                </SecondaryButton>
                            </a>
                            <PrimaryButton onClick={() => this.setState({showModal: true})} style={{...styles.primaryButton, display: 'inline', fontSize: '20px', padding: '10px 30px', width: '250px', borderRadius: '10px'}}>
                                {this.state.user.auto_apply_on ? 'Manage Auto Apply' : `Setup Auto Apply`}
                            </PrimaryButton>
                        </div>
                    </div>
                    <div style={{display: 'flex', flexWrap: 'wrap', gap: '50px', justifyContent: 'center', width: '100%', marginTop: '50px'}}>
                        <div style={{maxWidth: '550px', width: '100%', margin: '0 10px', display: 'flex', flexDirection: 'column',}}>
                            <div style={{alignSelf: 'center', fontWeight: '600', color: '#333', fontSize: '28px'}}>
                                Account
                            </div>
                            <div style={{marginTop: '10px', marginBottom: '20px', alignSelf: 'center', fontSize: '14px', color: '#999', fontWeight: '400'}}>
                                {`Account details / settings`}
                            </div>
                            <div style={styles.infoBox}>
                                <div style={styles.settingTitle}>
                                    Premium
                                </div>
                                <div style={styles.settingInfo}>
                                    No Active Subscription
                                </div>
                            </div>
                            <div style={styles.infoBoxWithAction}>
                                <div style={{display: 'flex', flexDirection: 'column', gap: '5px'}}>
                                    <div style={styles.settingTitle}>
                                        Auto Apply
                                    </div>
                                    <div style={styles.settingInfo}>
                                        {this.state.user.auto_apply_on ? 'On' : 'Off'}
                                    </div>
                                </div>
                                <div>
                                    { this.props.applications.length > 0 ? (
                                        <SecondaryButton onClick={this.updateAutoApply.bind(this, !this.state.user.auto_apply_on)} style={styles.secondaryButton}>
                                            Turn {this.state.user.auto_apply_on ? 'Off' : 'On'}
                                        </SecondaryButton>
                                    ) : (
                                        <SecondaryButton onClick={() => this.setState({showModal: true})} style={{...styles.secondaryButton}}>
                                            Setup
                                        </SecondaryButton>
                                    )}

                                </div>
                            </div>
                            <div style={styles.infoBox}>
                                <div style={styles.settingTitle}>
                                    Tokens
                                </div>
                                <div style={styles.settingInfo}>
                                    {this.state.user.unlock_tokens}
                                </div>
                            </div>
                        </div>
                        <div style={{maxWidth: '550px', width: '100%', display: 'flex', flexWrap: 'wrap', flexDirection: 'column'}}>
                            <div style={{alignSelf: 'center', fontWeight: '600', color: '#333', fontSize: '28px'}}>
                                Roles Applied For You
                            </div>
                            <div style={{marginTop: '10px', marginBottom: '20px', alignSelf: 'center', fontSize: '14px', color: '#999', fontWeight: '400'}}>
                                {`We have applied to ${applicationsSubmitted} roles at ${companiesAppliedTo} companies on your behalf.`}
                            </div>
                            <div style={{border: '1px solid #CCC', margin: '0 10px', display: 'inline-flex', flexWrap: 'wrap', alignItems: 'center', gap: '5px', justifyContent: 'center'}}>
                                {this.props.applications.map((application, index) => this.renderApplication(application, index))}
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderModal()}
                <Footer />
            </div>
        );
    }
}

export default Dashboard
