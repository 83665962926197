import React from "react"

const styles = {
    input: {
        color: 'white',
        fontSize: '16px',
        fontWeight: '600',
        backgroundColor: '#70b5ff',
        padding: '12px',
        display: 'inline-block',
        marginBottom: '4px',
        border: 'none',
    },
}

class Button extends React.Component {
    render () {
        return (
            <div>
                <input
                    style={styles.input}
                    type={this.props.type || "button"}
                    name={this.props.text}
                    value={this.props.text}
                />
            </div>
        );
    }
}

export default Button
