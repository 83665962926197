import React from "react"
import StallionsApi from "../helpers/api"
import styled from "styled-components"

const styles = {
    container: {
        margin: '20px',
        border: '1px solid #CCC',
        transition: '0.5s ease',
        width: '100%',
        borderRadius: '10px',
        padding: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        gap: '20px',
        minWidth: '400px',
    },
}

const Container = styled.a`
background-color: white;
:hover {
    background-color: #EEE !important;
}
`

class Compare extends React.Component {
    state = {
        selectedOption: false,
    }

    pickOption = () => {
        if (!this.state.selectedOption) {

            const query = {
                company_ids: this.props.company_ids,
                selected_company_id: this.props.id,
            }

            StallionsApi.fetch({
                method: "post",
                path: "/api/v0/compare/pick",
                query: query,
                callback: () => location.reload()
            })
        }

        this.setState({selectedOption: true})
    }

    render () {
        const logoUrl = this.props.logo_url ? this.props.logo_url : `//logo.clearbit.com/${this.props.link}`

        return (
            <Container style={styles.container} onClick={this.pickOption.bind(this)}>
                <img width="70px" height={"70px"} style={{objectFit: 'contain'}} src={logoUrl} />
                <div>
                    <div style={{fontWeight: '600', color: '#333', fontSize: '18px'}}>
                        {this.props.name}
                    </div>
                    <div style={{fontWeight: '400', color: '#999', fontSize: '14px'}}>
                        {`elo: ${Math.floor(this.props.elo)}`}
                    </div>
                </div>
            </Container>
        )
    }
}

export default Compare
