import React from "react"
import styled from "styled-components"

const styles = {
    checkbox: {
        borderRadius: '5px',
        width: '30px',
        height: '30px',
        border: '1px solid #CCC',
        backgroundColor: 'white',
        color: 'white',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        transition: 'all .2s ease',
    },
    active: {
        transition: 'none',
        border: '1px solid #ff5656',
        backgroundColor: '#ff5656',
    }
}

const CheckboxContainer = styled.div`
`

class Checkbox extends React.Component {
    constructor(props) {
        super(props);
    }

    updateActive(){
        if(this.props.updateFn){
            this.props.updateFn(!this.props.active);
        }
    }

    render() {
        return (
            <CheckboxContainer className="Checkbox">
                <input type="hidden" name={(this.props.model && this.props.field) ? (this.props.model + '[' + this.props.field + ']') : null} value={this.props.active} />
                <div style={{...styles.checkbox, ...(this.props.active ? styles.active : {})}} onClick={() => this.updateActive()}>
                    {this.props.active ? (<div className={"fa fa-check"} />) : null }
                </div>
            </CheckboxContainer>
        )
    }
}

export default Checkbox