import React from "react"
import Header from './Header'
import LoginBox from './LoginBox'

const styles = {
    homeContainer: {
        width: '100%',
        fontFamily: 'Lato',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    signupContainer: {
        maxWidth: '1024px',
        width: '100%',
        marginTop: '20px',
    },
}

class LoginUserPage extends React.Component {
    render () {
        return (
            <div style={styles.homeContainer}>
                <Header {...this.props} />
                <div style={styles.signupContainer}>
                    <LoginBox />
                </div>
            </div>
        );
    }
}

export default LoginUserPage
