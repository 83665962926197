import React from "react"

const styles = {
    container: {

    },
}

class MoreCompanyItem extends React.Component {
    render () {
        const { rank, name, slug } = this.props
        return (
            <a href={`/companies/${slug}`}>
                <div style={styles.container}>
                    {rank + '. ' + name}
                </div>
            </a>
        );
    }
}

export default MoreCompanyItem