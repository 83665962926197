import React from "react"
import Header from './Header'
import Footer from "./Footer"
import Checkbox from "./Checkbox";

const styles = {
    homeContainer: {
        width: '100%',
        fontFamily: 'Lato',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    termsContainer: {
        maxWidth: '1600px',
        width: '100%',
        margin: '0 0 20px 0',
        display: 'flex',
        flexDirection: 'column',
    },
    primaryButton: {
        backgroundColor: '#ff5656',
        color: 'white',
        padding: '10px 20px',
        borderRadius: '20px',
        fontWeight: '600',
        textAlign: 'center',
        cursor: 'pointer',
    },
    secondaryButton: {
        border: '1px solid #CCC',
        padding: '10px 20px',
        borderRadius: '20px',
        cursor: 'pointer',
        color: '#555',
    },
    info: {
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
        width: '300px'
    },
    infoTitle: {
        fontSize: '14px',
        color: '#999'
    },
    infoInfo: {
        padding: '10px 20px',
        color: '#333',
        fontSize: '16px',
        border: '1px solid #CCC',
        borderRadius: '10px',
    },
    input: {
        fontWeight: '500',
        padding: '10px',
        width: '300px',
        color: '#333',
        borderRadius: '10px',
        border: '1px solid #AAA',
        fontSize: '16px'
    },
}

class Company extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            link: ""
        }
    }

    render() {
        const {company} = this.props
        const logoUrl = company.logo_url ? company.logo_url : `//logo.clearbit.com/${company.link}`

        return (
            <div style={{width: '750px', padding: '5px 20px', display: 'flex', gap: '10px', alignItems: 'center', justifyContent: 'center'}}>
                <a href={company.careers_link} target={"_blank"}>
                    <img width="45px" height="45px" style={{objectFit: 'contain', borderRadius: company.rounded ? '50%' : '0%', marginLeft: '20px'}} src={logoUrl}/>
                </a>
                <a href={company.careers_link} target={"_blank"}>
                    <div style={{width: '100px'}}>
                        {company.name}
                    </div>
                </a>
                <input style={{...styles.input}} name={"link"} placeholder={"Job Post Link"} value={this.state.link} onChange={(e) => this.setState({link: e.target.value})} />
                <div style={styles.primaryButton}>
                    Submit
                </div>
                <div style={styles.secondaryButton}>
                    No Role
                </div>
            </div>
        )
    }
}

class AdminApply extends React.Component {
    renderCompany = (company) => {
        return <Company company={company} />
    }

    render () {
        return (
            <div style={styles.homeContainer}>
                <Header {...this.props} />
                <div style={{height: 20, width: '100%'}} />
                <div style={styles.termsContainer}>
                    <div style={{alignSelf: 'center', fontWeight: '600', color: '#333', fontSize: '28px'}}>
                        Admin Apply
                    </div>
                    <div style={{marginTop: '10px', alignSelf: 'center', fontSize: '14px', color: '#999', fontWeight: '400'}}>
                        {`12 users & 144 applications in queue`}
                    </div>
                    <div style={{marginTop: '20px', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <div style={{display: 'flex', gap: '20px', flexWrap: 'wrap', justifyContent: 'center'}}>
                            <div style={{fontSize: '20px', padding: '10px 30px', borderRadius: '10px', width: '250px', textAlign: 'center', border: '1px solid #CCC', color: '#666', cursor: 'pointer'}}>
                                Stop for Now
                            </div>
                            <div style={{...styles.primaryButton, display: 'inline', fontSize: '20px', padding: '10px 30px', width: '250px', borderRadius: '10px'}}>
                                Start Next User
                            </div>
                        </div>
                    </div>
                    <div style={{marginTop: '30px', alignSelf: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <div style={{fontSize: '16px', color: '#999', fontWeight: '400'}}>
                            You have completed
                        </div>
                        <div style={{fontSize: '20px', color: '#666', fontWeight: '600'}}>
                            27 applications in 2 hours
                        </div>
                    </div>
                    <div style={{display: 'flex', flexWrap: 'wrap', gap: '50px', justifyContent: 'center', width: '100%', marginTop: '50px'}}>
                        <div>
                            <div style={{marginBottom: '20px', textAlign: 'center', maxWidth: '620px', color: '#ff5656', fontWeight: '600', padding: '10px 20px', border: '1px solid #CCC', borderRadius: '10px'}}>
                                Download Resume
                            </div>
                            <div style={{display: 'flex', width: '620px', gap: '20px', flexWrap: 'wrap'}}>
                                <div style={{maxWidth: '300px'}}>
                                    <div style={styles.info}>
                                        <div style={styles.infoTitle}>
                                            First Name
                                        </div>
                                        <div style={styles.infoInfo}>
                                            Chris
                                        </div>
                                    </div>
                                </div>
                                <div style={{maxWidth: '300px'}}>
                                    <div style={styles.info}>
                                        <div style={styles.infoTitle}>
                                            Last Name
                                        </div>
                                        <div style={styles.infoInfo}>
                                            Gregorio
                                        </div>
                                    </div>
                                </div>
                                <div style={{maxWidth: '300px'}}>
                                    <div style={styles.info}>
                                        <div style={styles.infoTitle}>
                                            Email
                                        </div>
                                        <div style={styles.infoInfo}>
                                            zaguios@gmail.com
                                        </div>
                                    </div>
                                </div>
                                <div style={{maxWidth: '300px'}}>
                                    <div style={styles.info}>
                                        <div style={styles.infoTitle}>
                                            Phone Number
                                        </div>
                                        <div style={styles.infoInfo}>
                                            +16285029085
                                        </div>
                                    </div>
                                </div>
                                <div style={{maxWidth: '300px'}}>
                                    <div style={styles.info}>
                                        <div style={styles.infoTitle}>
                                            Linkedin
                                        </div>
                                        <div style={styles.infoInfo}>
                                            https://linkedin.com/in/zaguios
                                        </div>
                                    </div>
                                </div>
                                <div style={{maxWidth: '300px'}}>
                                    <div style={styles.info}>
                                        <div style={styles.infoTitle}>
                                            City
                                        </div>
                                        <div style={styles.infoInfo}>
                                            San Francisco, CA
                                        </div>
                                    </div>
                                </div>
                                <div style={{maxWidth: '300px'}}>
                                    <div style={styles.info}>
                                        <div style={styles.infoTitle}>
                                            Target Level
                                        </div>
                                        <div style={styles.infoInfo}>
                                            Senior
                                        </div>
                                    </div>
                                </div>
                                <div style={{maxWidth: '300px'}}>
                                    <div style={styles.info}>
                                        <div style={styles.infoTitle}>
                                            Target Role
                                        </div>
                                        <div style={styles.infoInfo}>
                                            Fullstack Engineer
                                        </div>
                                    </div>
                                </div>
                                <div style={{maxWidth: '300px'}}>
                                    <div style={styles.info}>
                                        <div style={styles.infoTitle}>
                                            Authorized to work in USA?
                                        </div>
                                        <div style={styles.infoInfo}>
                                            Yes
                                        </div>
                                    </div>
                                </div>
                                <div style={{maxWidth: '300px'}}>
                                    <div style={styles.info}>
                                        <div style={styles.infoTitle}>
                                            Requires Visa Sponsorship?
                                        </div>
                                        <div style={styles.infoInfo}>
                                            No
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{width: '750px'}}>
                            <div style={{display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: '5px', justifyContent: 'center'}}>
                                {this.props.companies.map(this.renderCompany)}
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default AdminApply
