import React from "react"
import JobListItem from './JobListItem'

const styles = {
    companyListContainer: {
      
    }
}

class JobList extends React.Component {
    renderJobListItem = (job, last, index) => <JobListItem {...job} last={last} index={index} />

    renderJobs = (jobs) => jobs.map((job, index) => this.renderJobListItem(job, (index + 1) === jobs.length, index))

    renderDepartments = () => {
        return this.props.jobs.map((job, index) => {
            return (
                <div>
                    <h3>{job.department}</h3>
                    {this.renderJobs(job.jobs)}
                </div>
            )
        })
    }

    renderHeader = () => {
        return (
            <div>

            </div>
        )
    }

    render () {
        return (
            <div style={styles.companyListContainer}>
                {this.renderHeader()}
                {this.renderDepartments()}
            </div>
        );
    }
}

export default JobList
