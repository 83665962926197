import React, {useState, useEffect} from "react"
import Dropdown from "./Dropdown";
import styled from "styled-components";
import moment from "moment";
import {GoogleOAuthProvider, useGoogleLogin} from "@react-oauth/google";
import ReactMarkdown from 'react-markdown'

const styles = {
    titleSection: {
        display: 'inline-flex',
        alignItems: 'center',
    },
    name: {
        fontWeight: '500',
        fontSize: '24px',
    },
    primaryButton: {
        backgroundColor: '#ff5656',
        color: 'white',
        padding: '10px 20px',
        borderRadius: '20px',
        fontWeight: '600',
        textAlign: 'center',
        cursor: 'pointer',
    },
    secondaryButton: {
        border: '1px solid #CCC',
        padding: '10px 20px',
        borderRadius: '20px',
        cursor: 'pointer',
        color: '#555',
    },
    emptyButton: {
        userSelect: 'none',
        padding: '10px 20px',
        borderRadius: '20px',
        width: '250px',
        textAlign: 'center',
        border: '1px solid #CCC',
        color: '#666',
        cursor: 'not-allowed'
    },
}

const PrimaryButton = styled.div`
transition: 0.2s;
:hover {
    background-color: #ff7171 !important;
}
`

const SecondaryButton = styled.div`
transition: 0.2s;
:hover {
    background-color: #333 !important;
    color: #FFF !important;
    border: 1px solid #333 !important;
}
`

const Question = ({wasLiked, wasDisliked, wasSeen, wasReported, createUnlock, question, index, firstBlurIndex, user, unlockTokens, missingQuestionCount}) => {
    const [expanded, setExpanded] = useState(question.problem.length < 1000)

    const [liked, setLiked] = useState(wasLiked)
    const [disliked, setDisliked] = useState(wasDisliked)
    const [seen, setSeen] = useState(wasSeen)
    const [reported, setReported] = useState(wasReported)

    const [likes, setLikes] = useState(question.likes)
    const [dislikes, setDislikes] = useState(question.dislikes)
    const [timesSeen, setTimesSeen] = useState(question.times_seen)

    const [voting, setVoting] = useState(false)

    const [hover, setHover] = useState(null)

    const submitVote = (vote_type) => {
        const token = document.querySelector('[name=csrf-token]').content
        const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}

        let vote_action = null

        if (vote_type === 'like') {
            vote_action = liked ? 'inactive' : 'active'
        }
        if (vote_type === 'dislike') {
            vote_action = disliked ? 'inactive' : 'active'
        }
        if (vote_type === 'seen') {
            vote_action = seen ? 'inactive' : 'active'
        }
        if (vote_type === 'report') {
            vote_action = reported ? 'inactive' : 'active'
        }

        const body = {
            vote_type,
            vote_action,
            question_id: question.id
        }

        fetch(`${window.location.origin}/api/v0/votes`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(body),
        }).then(res => res.json())
            .then(res => {
                if (res.success) {
                    console.log("vote sent")
                    setVoting(false)
                }
            })
            .catch(err => {
            })
    }

    const vote = (voteType) => {

        if (voting) {
            return
        }
        setVoting(true)

        submitVote(voteType)

        if (voteType === 'like') {
            if (!liked) {
                if (disliked) {
                    setDislikes(x => x - 1)
                }
                setDisliked(false)
                setLiked(true)
                setLikes(x => x + 1)
            } else {
                setLikes(x => x - 1)
                setLiked(false)
            }
        }
        if (voteType === 'dislike') {
            if (!disliked) {
                if (liked) {
                    setLikes(x => x - 1)
                }
                setLiked(false)
                setDisliked(true)
                setDislikes(x => x + 1)
            } else {
                setDislikes(x => x - 1)
                setDisliked(false)
            }
        }
        if (voteType === 'seen') {
            if (!seen) {
                setSeen(true)
                setTimesSeen(x => x + 1)
            } else {
                setSeen(false)
                setTimesSeen(x => x - 1)
            }
        }
        if (voteType === 'report') {
            if (!reported) {
                setReported(true)
            } else {
                setReported(false)
            }
        }
    }

    return (
        <div style={{position: 'relative'}}>
            <div style={{backgroundColor: index % 2 === 0 ? '#F6F6F6' : '#FFF', filter: question.is_blurred ? 'blur(6px)' : null, userSelect: question.is_blurred ? 'none' : null}}>
                <div style={{display: 'flex', flexWrap: 'wrap', gap: '10px', color: '#999', fontSize: '14px', padding: '20px 20px 0'}}>
                    <div>
                        {moment(question.created_at).utc().format('MMM D, YYYY')}
                    </div>
                    <div>
                        |
                    </div>
                    <div style={{color: '#ff5656'}}>
                        {question.interview_level}
                    </div>
                    <div>
                        |
                    </div>
                    <div>
                        {question.role}
                    </div>
                    <div>
                        |
                    </div>
                    <div style={{color: '#ff5656'}}>
                        {question.interview_name + ' - ' + question.interview_type}
                    </div>
                </div>
                <div style={{fontSize: '16px', color: question.is_blurred ? '#CCC' : '#333', maxHeight: !expanded ? '220px' : null, overflowY: 'hidden', position: 'relative'}}>
                    <div style={{padding: '0 20px'}}>
                        <ReactMarkdown>
                            {question.problem}
                        </ReactMarkdown>
                        <div style={{display: 'flex', gap: '30px', alignItems: 'center', marginBottom: '20px'}}>
                            <div style={{display: 'flex', alignItems: 'center', gap: '10px', position: 'relative'}}>
                                <i onMouseOver={() => setHover("like")} onMouseOut={() => setHover(null)} onClick={() => vote('like')} style={{fontSize: '20px', color: liked ? '#ff5656' : '#CCC', cursor: 'pointer'}} className="fas fa-thumbs-up" />
                                {likes}
                                <div style={{zIndex: 260, position: 'absolute', textAlign: 'center', width: '100px', display: hover === 'like' ? 'block' : 'none', backgroundColor: 'white', bottom: '25px', borderRadius: '20px', padding: '10px', color: '#666', border: '1px solid #CCC'}}>
                                    Useful!
                                </div>
                            </div>
                            <div style={{display: 'flex', alignItems: 'center', gap: '10px', position: 'relative'}}>
                                <i onMouseOver={() => setHover("dislike")} onMouseOut={() => setHover(null)} onClick={() => vote('dislike')} style={{fontSize: '20px', color: disliked ? '#ff5656' : '#CCC', cursor: 'pointer'}} className="fas fa-thumbs-down" />
                                {dislikes}
                                <div style={{position: 'absolute', textAlign: 'center', width: '150px', display: hover === 'dislike' ? 'block' : 'none', backgroundColor: 'white', bottom: '25px', borderRadius: '20px', padding: '10px', color: '#666', border: '1px solid #CCC'}}>
                                    NOT useful!
                                </div>
                            </div>
                            <div style={{display: 'flex', alignItems: 'center', gap: '10px', position: 'relative'}}>
                                <i onMouseOver={() => setHover("seen")} onMouseOut={() => setHover(null)} onClick={() => vote('seen')} style={{fontSize: '20px', color: seen ? '#ff5656' : '#CCC', cursor: 'pointer'}} className="fas fa-hand-paper" />
                                {timesSeen}
                                <div style={{position: 'absolute', textAlign: 'center', width: '300px', display: hover === 'seen' ? 'block' : 'none', backgroundColor: 'white', bottom: '25px', borderRadius: '20px', padding: '10px', color: '#666', border: '1px solid #CCC'}}>
                                    I saw this same question
                                </div>
                            </div>
                            <div onMouseOver={() => setHover("report")} onMouseOut={() => setHover(null)} onClick={() => vote('report')} style={{color: '#999', cursor: 'pointer', position: 'relative'}}>
                                {reported ? 'Unreport' : 'Report'}
                                <div style={{position: 'absolute', textAlign: 'center', width: '150px', display: hover === 'report' ? 'block' : 'none', backgroundColor: 'white', bottom: '25px', borderRadius: '20px', padding: '10px', color: '#666', border: '1px solid #CCC'}}>
                                    Spam / Fake
                                </div>
                            </div>

                        </div>
                    </div>
                    <div onClick={() => setExpanded(true)} style={{cursor: 'pointer', display: expanded ? 'none' : 'flex', flexDirection: 'column', bottom: 0, position: 'absolute', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
                        <div style={{width: '100%', height: '50px', backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.0), rgba(255, 255, 255, 1.0))'}}>

                        </div>
                        <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#FFF', borderRadius: '10px', color: '#ff5656', fontSize: '14px'}}>
                            Click To Expand
                        </div>
                    </div>
                </div>

            </div>
            {
                firstBlurIndex === index && (
                    <div style={{position: 'absolute', width: '100%', textAlign: 'center', zIndex: 10, display: 'flex', flexDirection: 'column', gap: '20px', padding: '50px'}}>
                        <i style={{fontSize: '50px', color: '#ff5656'}} className="fas fa-crown" />
                        <div style={{fontWeight: '600', fontSize: '24px', width: '100%', color: '#333'}}>
                            {`Unlock ${missingQuestionCount} More by Contributing!`}
                        </div>
                        <div style={{fontSize: '16px', width: '100%', color: '#666'}}>
                            Contribute the questions you were asked during interviews & get access to others contributions! Or buy premium if you want to support the site!
                        </div>
                        <div style={{display: 'flex', gap: '20px', justifyContent: 'center', alignItems: 'center'}}>
                            <SecondaryButton onClick={() => createUnlock()} style={{fontSize: '20px', padding: '10px 30px', borderRadius: '10px', width: '220px', textAlign: 'center', border: '1px solid #CCC', color: '#666', cursor: 'pointer'}}>
                                {`Unlock (${unlockTokens || 0} Left)`}
                            </SecondaryButton>
                            { user ? (
                                <a href={"/premium"} target={"_blank"}>
                                    <PrimaryButton style={{...styles.primaryButton, fontSize: '20px', padding: '10px 30px', width: '220px', borderRadius: '10px'}}>
                                        Get Premium
                                    </PrimaryButton>
                                </a>
                            ) : (
                                <PrimaryButton onClick={() => googleLogin()} style={{...styles.primaryButton, fontSize: '20px', padding: '10px 30px', width: '220px', borderRadius: '10px'}}>
                                    Sign In
                                </PrimaryButton>
                            )}

                        </div>
                    </div>
                )
            }
        </div>
    )
}

const CompanyDetails = ({company, mobile, user, setUser}) => {

    const [interviewLevel, setInterviewLevel] = useState('Select Level')
    const [interviewType, setInterviewType] = useState('Select Type')
    const [interviewName, setInterviewName] = useState('Select Interview')
    const [role, setRole] = useState('Select Role')
    const [isUnlocked, setIsUnlocked] = useState(false)
    const [missingQuestionCount, setMissingQuestionCount] = useState(0)

    const [filterInterviewLevel, setFilterInterviewLevel] = useState('Select Level')
    const [filterInterviewType, setFilterInterviewType] = useState('Select Type')
    const [filterInterviewName, setFilterInterviewName] = useState('Select Interview')
    const [filterRole, setFilterRole] = useState('Select Role')

    const [loading, setLoading] = useState(true)

    const [questions, setQuestions] = useState([])
    const [unlockTokens, setUnlockTokens] = useState(0)
    const [voteMap, setVoteMap] = useState({})


    const [problem, setProblem] = useState('')

    const logoUrl = company.logo_url ? company.logo_url : `//logo.clearbit.com/${company.link}`

    useEffect(() => {
        fetchQuestions(company)
        fetchUnlock()
        fetchUser()
    }, [])

    const organizeVotes = (votes) => {
        let map = {}

        votes.forEach((vote) => {
            if (map[vote.question_id] === undefined){
                map[vote.question_id] = {}
            }
            map[vote.question_id][vote.vote_type] = true
        })

        setVoteMap(map)
    }

    const createUnlock = () => {
        const token = document.querySelector('[name=csrf-token]').content
        const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}

        const body = {
            company_id: company.id
        }

        fetch(`${window.location.origin}/api/v0/unlocks`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(body),
        }).then(res => res.json())
            .then(res => {
                if (res.success) {
                    setQuestions(res.questions)
                    organizeVotes(res.votes)
                    setIsUnlocked(true)
                }
            })
            .catch(err => {
            })
    }

    const submitQuestion = () => {
        const token = document.querySelector('[name=csrf-token]').content
        const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}

        const body = {
            question: {
                company_id: company.id,
                problem: problem,
                interview_level: interviewLevel,
                interview_type: interviewType,
                interview_name: interviewName,
                role
            }
        }

        fetch(`${window.location.origin}/api/v0/questions`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(body),
        }).then(res => res.json())
            .then(res => {
                if (res.success) {
                    setQuestions([res.question, ...questions])
                    setProblem("")
                }
            })
            .catch(err => {
            })
    }

    const fetchUser = () => {
        const token = document.querySelector('[name=csrf-token]').content
        const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}
        const body = {}

        fetch(`${window.location.origin}/api/v0/users/${user?.id}`, {
            method: "GET",
            headers: headers,
        }).then(res => res.json())
            .then(res => {
                if (res.success) {
                    setUnlockTokens(res.user.unlock_tokens)
                }
            })
            .catch(err => {
            })
    }

    const fetchUnlock = () => {
        const token = document.querySelector('[name=csrf-token]').content
        const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}

        fetch(`${window.location.origin}/api/v0/unlocks?company_id=${company.id}`, {
            method: "GET",
            headers: headers,
        }).then(res => res.json())
            .then(res => {
                if (res.success) {
                    if (res.is_unlocked) {
                        setIsUnlocked(true)
                        setUnlockTokens(x => x - 1)
                    }
                }
            })
            .catch(err => {
            })
    }

    const fetchQuestions = (company) => {
        const token = document.querySelector('[name=csrf-token]').content
        const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}
        const body = {
            company_id: company.id
        }

        fetch(`${window.location.origin}/api/v0/questions?company_id=${company.id}`, {
            method: "GET",
            headers: headers,
        }).then(res => res.json())
            .then(res => {
                if (res.success) {
                    setQuestions(res.questions)
                    organizeVotes(res.votes)
                    setLoading(false)
                    setMissingQuestionCount(res.filtered_out_count)
                }
            })
            .catch(err => {
            })
    }

    const renderQuestion = (question, index, firstBlurIndex) => {

        let liked = voteMap[question.id]?.['like']
        let disliked = voteMap[question.id]?.['dislike']
        let seen = voteMap[question.id]?.['seen']
        let reported = voteMap[question.id]?.['report']

        return <Question wasLiked={liked} wasDisliked={disliked} wasSeen={seen} wasReported={reported} missingQuestionCount={missingQuestionCount} unlockTokens={unlockTokens} createUnlock={createUnlock} question={question} index={index} firstBlurIndex={firstBlurIndex} user={user} />
    }

    const renderQuestionList = () => {
        let questionList = questions.slice()

        questionList = questionList.filter(question => {
            if (filterRole !== 'Select Role' && filterRole !== question.role) {
                return false
            }
            if (filterInterviewName !== 'Select Interview' && filterInterviewName !== question.interview_name) {
                return false
            }
            if (filterInterviewLevel !== 'Select Level' && filterInterviewLevel !== question.interview_level) {
                return false
            }
            if (filterInterviewType !== 'Select Type' && filterInterviewType !== question.interview_type) {
                return false
            }

            return true
        })

        if (loading) {
            return (
                <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <div className={"lds-facebook"}>
                        <div />
                        <div />
                        <div />
                    </div>
                </div>
            )
        }

        if (!isUnlocked) {
            for (let i = 0; i < 5; i++) {
                questionList.push({
                    is_blurred: true,
                    created_at: 'Feb 30, 2023',
                    interview_level: 'L9',
                    role: 'Hacker',
                    interview_name: 'CSS Editing',
                    interview_type: 'Frontend',
                    problem: 'Nice try removing the blur, but you are gonna need to contribute a question or purchase premium to see more questions.'
                })
            }
        }

        return (
            <div>
                {questionList.map((q, indx) => renderQuestion(q, indx, questions.length))}
            </div>
        )
    }

    const googleLogin = useGoogleLogin({
        scope: "email profile",
        onSuccess: async tokenResponse => {
            let body = {
                access_token: tokenResponse.access_token
            }
            const token = document.querySelector('[name=csrf-token]').content
            const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}

            fetch(`${window.location.origin}/api/v0/auths`, {
                method: "POST",
                headers: headers,
                body: JSON.stringify(body),
            }).then(res => res.json())
                .then(res => {
                    if (res.success) {
                        setUser && setUser(res.user)
                    } else {

                    }
                })
                .catch(err => {

                })
        },
    });

    const renderSubmitButton = () => {
        let submitEnabled = true

        if (interviewLevel === 'Select Level') {
            submitEnabled = false
        }
        if (interviewType === 'Select Type') {
            submitEnabled = false
        }
        if (interviewName === 'Select Interview') {
            submitEnabled = false
        }
        if (role === 'Select Role') {
            submitEnabled = false
        }
        if (problem.length < 100) {
            submitEnabled = false
        }

        if (!user) {
            return (

                <PrimaryButton onClick={() => googleLogin()} style={{...styles.primaryButton, display: 'inline'}}>
                    Create Account to Submit
                </PrimaryButton>
            )
        }

        if (submitEnabled) {
            return (
                <PrimaryButton onClick={() => submitQuestion()} style={{...styles.primaryButton, display: 'inline'}}>
                    Submit
                </PrimaryButton>
            )
        }

        return (
            <div style={{display: 'flex', gap: '20px', alignItems: 'center'}}>
                <div style={{...styles.emptyButton, display: 'inline'}}>
                    Submit
                </div>
                <div style={{color: '#999', fontSize: '12px'}}>
                    Please select choose all 4 categories & write at least 100 characters to submit
                </div>
            </div>
        )
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', gap: '50px'}}>
            <div style={{position: 'sticky', zIndex: 95, top: '-30px', paddingTop: '30px', width: '100%', alignItems: 'center', display: 'flex', justifyContent: 'space-between', backgroundColor: 'white'}}>
                <a target="_blank" href={company.link}>
                    <div style={styles.titleSection}>
                        <img height="50px" src={logoUrl} />
                        <div style={{marginLeft: '10px'}}>
                            <div style={styles.name}>
                                {company.name}
                            </div>
                            <div style={{color: '#999', fontSize: '12px'}}>
                                {`#${company.rank || '-'} | ${Math.floor(company.elo)} Elo`}
                            </div>
                        </div>
                    </div>
                </a>
                <div style={{display: 'flex', gap: '20px'}}>
                    { !mobile && (
                        <div>
                            <a href={company.levels_link ?? `https://www.levels.fyi/companies/${company.slug}/salaries/software-engineer`} target="_blank">
                                <SecondaryButton style={styles.secondaryButton}>
                                    Levels.fyi
                                </SecondaryButton>
                            </a>
                        </div>
                    )}
                    <div>
                        <a href={company.careers_link} target="_blank">
                            <PrimaryButton style={styles.primaryButton}>
                                {mobile ? 'Careers' : `Careers Page`}
                            </PrimaryButton>
                        </a>
                    </div>
                </div>
            </div>
            <div style={{width: '100%', display: 'flex', flexWrap: mobile ? 'wrap' : 'nowrap', gap: '20px'}}>
                <div style={{flexBasis: mobile ? '100%' : '50%'}}>
                    <div style={{fontWeight: '500', fontSize: '20px', marginBottom: '10px'}}>
                        Interview Questions
                    </div>
                    <div style={{display: 'flex', flexWrap: 'wrap', gap: '20px', marginBottom: '20px'}}>
                        <Dropdown options={['Select Level', 'Entry', 'Mid', 'Senior', 'Staff', 'Sr. Staff']} selected={filterInterviewLevel} onSelect={(il) => setFilterInterviewLevel(il)} />
                        <Dropdown options={['Select Type', 'Phone Screen', 'Onsite', 'Online Assessment', 'Take Home']} selected={filterInterviewType} onSelect={(it) => setFilterInterviewType(it)} />
                        <Dropdown options={['Select Interview', 'Coding', 'System Design', 'Behavioral', 'Project Deep Dive', 'Debugging', 'Other']} selected={filterInterviewName} onSelect={(iName) => setFilterInterviewName(iName)} />
                        <Dropdown options={['Select Role', 'General SWE', 'Frontend', 'Backend', 'Fullstack', 'Engineering Manager', "Machine Learning Eng", 'Data Science', 'DevOps', 'Security', 'Site Reliability Engineer', 'Mobile Engineer', "Product Manager", 'Other']} selected={filterRole} onSelect={(r) => setFilterRole(r)} />
                    </div>
                    {renderQuestionList()}
                </div>
                <div style={{flexBasis: mobile ? '100%' : '50%'}}>
                    <div style={{fontWeight: '500', fontSize: '20px', marginBottom: '10px'}}>
                        Submit A Question
                    </div>
                    <div style={{display: 'flex', flexWrap: 'wrap', gap: '20px', marginBottom: '20px'}}>
                        <Dropdown options={['Select Level', 'Entry', 'Mid', 'Senior', 'Staff', 'Sr. Staff']} selected={interviewLevel} onSelect={(il) => setInterviewLevel(il)} />
                        <Dropdown options={['Select Type', 'Phone Screen', 'Onsite', 'Online Assessment', 'Take Home']} selected={interviewType} onSelect={(it) => setInterviewType(it)} />
                        <Dropdown options={['Select Interview', 'Coding', 'System Design', 'Behavioral', 'Project Deep Dive', 'Debugging', 'Other']} selected={interviewName} onSelect={(iName) => setInterviewName(iName)} />
                        <Dropdown options={['Select Role', 'General SWE', 'Frontend', 'Backend', 'Fullstack', 'Engineering Manager', "Machine Learning Eng", 'Data Science', 'DevOps', 'Security', 'Site Reliability Engineer', 'Mobile Engineer', "Product Manager", 'Other']} selected={role} onSelect={(r) => setRole(r)} />
                    </div>
                    <textarea disabled={!user} rows={"4"} value={problem} onChange={(e) => setProblem(e.target.value)} placeholder={`Enter a question you were asked when you interviewed with ${company.name}. (Markdown supported)`} style={{border: '1px solid #CCC', width: '100%', padding: '20px', fontSize: '16px', borderRadius: '20px', fontFamily: 'lato, arial'}} />
                    <div style={{marginTop: '20px'}}>
                        {renderSubmitButton()}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CompanyDetails
