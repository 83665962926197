import React from 'react';

const styles = {
    dropdownBox: {
        position: 'absolute',
        zIndex: '20',
        marginTop: '10px',
        borderRadius: '15px',
        width: '300px',
        boxShadow: 'rgb(0 0 0 / 15%) 0px 2px 8px',
    },
    searchField: {
        width: '100%',
        height: '40px',
        padding: '20px',
        border: '1px solid #CCC',
        borderRadius: '20px',
        position: 'relative'
    },
    searchBox: {
        boxShadow: 'rgb(0 0 0 / 15%) 0px 2px 8px',
        borderRadius: '20px'
    },
    suggestion: {
        height: '70px',
        padding: '15px',
        color: '#555',
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
    }
}

class CompanySearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = { search: '', suggestions: [], activeIndex: 0 };
    }

    handleChange = (e) => {
        let suggestions = this.props.companies.filter((company) => company.name.toLowerCase().startsWith(e.target.value.toLowerCase())).slice(0, 5)

        this.setState({[e.target.name]: e.target.value, suggestions})
    }

    handleKeypress = (e) => {
        let activeIndex = this.state.activeIndex
        if (e.code === "ArrowUp") {
            activeIndex -= 1
            if (activeIndex < 0) {
                activeIndex = this.state.suggestions.length - 1
            }
            this.setState({activeIndex})
        }
        if (e.code === "ArrowDown") {
            activeIndex += 1
            if (activeIndex >= this.state.suggestions.length) {
                activeIndex = 0
            }
            this.setState({activeIndex})
        }
    }

    handleClickOutside = (event) => {
        if (event.target.classList[0] !== "option") {
            this.setState({suggestions: []})
        }
    }

    componentDidMount() {
        window.addEventListener("keydown", this.handleKeypress)
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this.handleKeypress)
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    handleSelect = (e) => {
        e.preventDefault()
        if (this.state.suggestions.length > 0) {
            if (this.props.search) {
                this.props.search(this.state.suggestions[this.state.activeIndex])
                this.setState({search: '', suggestions: [], activeIndex: 0})
            } else if (this.state.activeIndex < this.state.suggestions.length) {
                window.location.href = '/companies/' + this.state.suggestions[this.state.activeIndex].slug
            }
        }
    };

    gotoCompany = (company) => {
        if (company) {
            if (this.props.search) {
                this.props.search(this.state.suggestions[this.state.activeIndex])
                this.setState({search: '', suggestions: [], activeIndex: 0})
            } else {
                window.location.href = `/companies/${company.slug}`
            }
        }
    }

    render() {
        return (
            <form autoComplete="off" onSubmit={this.handleSelect}>
                <input placeholder="Search Company..." value={this.state.search} name="search" onChange={this.handleChange} style={styles.searchField} className={'no-outline'} />
                { this.state.search.length > 0 && this.state.suggestions.length > 0 && (
                    <div style={styles.dropdownBox}>
                        {this.state.suggestions.map((suggestion, index) => {
                            const style = index === this.state.activeIndex
                                ? { backgroundColor: '#F6F6F6', cursor: 'pointer' }
                                : { backgroundColor: '#ffffff', cursor: 'pointer' };

                            if (index === 0) {
                                style.borderTopLeftRadius = "20px"
                                style.borderTopRightRadius = "20px"
                            }

                            if (index === this.state.suggestions.length - 1) {
                                style.borderBottomLeftRadius = "20px"
                                style.borderBottomRightRadius = "20px"
                            }

                            const logoUrl = suggestion.logo_url ? suggestion.logo_url : `//logo.clearbit.com/${suggestion.link}`

                            return (
                                <div onClick={() => this.gotoCompany(suggestion)} style={style} onMouseOver={() => this.setState({activeIndex: index})}>
                                    <div className={"option"} style={styles.suggestion}>
                                        <img className={"option"} width="40px" src={logoUrl} />
                                        {suggestion.name}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}
            </form>
        );
    }
}

export default CompanySearch