import React, { useState, useEffect, useRef } from 'react';
import styled from "styled-components";

const styles = {
    selectBox: {
        border: '1px solid #CCC',
        backgroundColor: 'white',
        padding: '10px 20px',
        fontSize: '14px',
        color: '#333',
        position: 'relative',
        width: '200px',
        borderRadius: '20px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    optionContainer: {
        marginTop: '10px',
        backgroundColor: 'white',
        boxShadow: 'rgb(0, 0, 0, 0.15) 0px 2px 8px',
        borderRadius: '20px',
        position: 'absolute',
        width: '200px',
        zIndex: 300,
        maxHeight: '270px',
        overflow: 'hidden',
        overflowY: 'scroll',
    },
    option: {
        padding: '20px',
        cursor: 'pointer',
    }
}

const HoverStyle = styled.div`
transition: 0.3s;
:hover {
    background-color: #F6F6F6;
}
`

const Dropdown = ({ options, selected, onSelect }) => {
    const [open, setOpen] = useState(false);

    const wrapperRef = useRef(null)
    const targetRef = useRef(null)

    useEffect(() => {
        function handleClickOutside(event) {
            if (targetRef.current && !targetRef.current.contains(event.target)) {
                if (wrapperRef.current && wrapperRef.current.contains(event.target)) {

                } else {
                    setOpen(false)
                }
            }

        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef]);

    const toggleDropdown = () => {
        setOpen(!open);
    };

    const handleSelect = (option) => {
        onSelect(option);
        toggleDropdown();
    };

    return (
        <div ref={wrapperRef} className="dropdown" style={{position: 'relative'}}>
            <button ref={targetRef} style={styles.selectBox} onClick={toggleDropdown}>
                {selected}
                <i style={{fontSize: '20px', color: '#666'}} className="fas fa-chevron-down" />
            </button>
            {open && (
                <div style={styles.optionContainer}>
                    {options.map((option, index) => (
                        <HoverStyle style={{...styles.option, borderTopLeftRadius: index === 0 ? '20px' : '0', borderTopRightRadius: index === 0 ? '20px' : '0', borderBottomLeftRadius: index === options.length - 1 ? '20px' : '0', borderBottomRightRadius: index === options.length - 1 ? '20px' : '0'}} key={option} onClick={() => handleSelect(option)}>
                            {option}
                        </HoverStyle>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Dropdown;