import React from "react"
import CompanyJobListItem from './CompanyJobListItem'
import Select from 'react-select';
import styled from 'styled-components'

const roleOptions = [
  {
    label: 'Engineering',
    options: [
      { value: 'software-engineer', label: 'Software Engineer' },
      { value: 'mobile-developer', label: 'Mobile Developer' },
      { value: 'full-stack-engineer', label: 'Full Stack Engineer' },
      { value: 'frontend-engineer', label: 'Frontend Engineer' },
      { value: 'backend-engineer', label: 'Backend Engineer' },
      { value: 'engineering-manager', label: 'Engineering Manager' },
      { value: 'security-engineer', label: 'Security Engineer' },
      { value: 'devops-engineer', label: 'DevOps Engineer' },
    ]
  },
  {
    label: 'Other',
    options: [
      { value: 'product-manager', label: 'Product Manager' },
      { value: 'product-designer', label: 'Product Designer'},
      { value: 'data-scientist', label: 'Data Scientist' }
    ]
  }
];

const locationOptions = [
  { value: 'san-franciso', label: 'San Francisco' },
  { value: 'bay-area', label: 'Bay Area' },
  { value: 'new-york', label: 'New York' },
  { value: 'seattle', label: 'Seattle' },
  { value: 'los-angeles', label: 'Los Angeles' },
  { value: 'remote', label: 'Remote' },
];

const styles = {
    companyListContainer: {
        backgroundColor: 'white',
        boxShadow: 'rgb(0, 0, 0, 0.15) 0px 2px 8px',
        width: '100%',
    },
    tableRow: {
        height: '45px',
        border: 'none',
    },
    tableColumn: {
        height: 'inherit',
        padding: '0 15px',
        color: '#999',
    },
    centeredColumn: {
        height: 'inherit',
        padding: '0 15px',
        textAlign: 'center',
        color: '#999',
    },
    filterContainer: {
      display: 'flex',
    },
    filter: {
      flexGrow: 5,
      padding: '10px',
    },
    submitContainer: {
      flexGrow: 1,
      padding: '10px',
    },
    submitButton: {
      color: 'white',
      fontSize: '14px',
      fontWeight: '600',
      backgroundColor: '#70b5ff',
      padding: '10px',
      display: 'inline-block',
    },
}

const SORT_VARS = {
    rank: {
        value: 'rank',
        defaultOrder: 'asc',
        compareType: 'number',
    },
    name: {
        value: 'name',
        defaultOrder: 'asc',
        compareType: 'string',
    },
    salary_entry_amount: {
        value: 'salary_entry_amount',
        defaultOrder: 'desc',
        compareType: 'number',
    },
    salary_midlevel_amount: {
        value: 'salary_midlevel_amount',
        defaultOrder: 'desc',
        compareType: 'number',
    },
    salary_senior_amount: {
        value: 'salary_entry_amount',
        defaultOrder: 'desc',
        compareType: 'number',
    }
}

const filterStyles = {
  control: (_, { selectProps: { flexGrow }}) => ({
    flexGrow: flexGrow
  }),
}

const SubmitHover = styled.div`
:hover {
    background-color: #7abaff !important;
}
`

class CompanyJobList extends React.Component {

    constructor(props) {
        super(props)

        let companies = props.companies
        for (let i = 0; i < companies.length; i++) {
            companies[i].rank = i + 1;
        }

        this.state = {
            sortType: 'rank',
            sortOrder: 'asc',
            companies: companies,
            selectedRoles: null,
            selectedLocations: null,
        }
    }

    reorder = type => {
        let sortVars = SORT_VARS[type]

        let sortOrder = this.state.sortOrder
        if (sortVars.value === this.state.sortType) {
            if (sortOrder === 'asc') {
                sortOrder = 'desc'
            } else {
                sortOrder = 'asc'
            }
        } else {
            sortOrder = sortVars.defaultOrder
        }


        let companies = this.state.companies.sort((a, b) => {
            let result
            if (sortVars.compareType === 'string') {
                result = a[type].localeCompare(b[type])
            } else {
                let aValue = parseInt(a[type])
                let bValue = parseInt(b[type])

                if (isNaN(aValue)) {
                    aValue = -1
                }

                if (isNaN(bValue)) {
                    bValue = -1
                }

                result = aValue - bValue
            }

            if (sortOrder === 'desc') {
                result *= -1
            }
            return result
        })

        this.setState({
            sortOrder: sortOrder,
            sortType: type,
            companies: companies,
        })
    }

    handleRoleChange = selectedRoles => {
      this.setState({ selectedRoles });
    };

    handleLocationChange = selectedLocations => {
      this.setState({ selectedLocations });
    };

    submitFilters = () => {
      if (!(this.state.selectedRoles || this.state.selectedLocations)) {
        return false
      }

      const roles = this.state.selectedRoles ? this.state.selectedRoles.map((item, index) => item.value ) : []
      const locations = this.state.selectedLocations ? this.state.selectedLocations.map((item, index) => item.value ) : []

      window.location = `/jobs?locations=${locations.join(',')}&roles=${roles.join(',')}`
    }

    renderCompanyListItem = (company, last, index) => <CompanyJobListItem {...company.company} jobs={company.departments} user={this.props.user} last={last} index={index} />

    renderCompanies = () => this.state.companies.map((company, index) => this.renderCompanyListItem(company, (index + 1) === this.props.companies.length, index))

    renderHeader = () => {
        return (
            <div style={styles.tableRow} key={-1}>
                <td style={{...styles.tableColumn, textAlign: 'center'}} onClick={this.reorder.bind(this, 'rank')}>
                    #
                </td>
                <td style={styles.tableColumn} onClick={this.reorder.bind(this, 'name')}>
                    Company
                </td>
                <td style={styles.centeredColumn} onClick={this.reorder.bind(this, 'salary_entry_amount')}>
                    Listings
                </td>
            </div>
        )
    }

    renderFilters = () => {
      const { selectedRoles, selectedLocations } = this.state;
      return (
        <div>
          <td colspan='3'>
            <div style={styles.filterContainer}>
              <div style={styles.filter}>
                <Select
                  value={selectedRoles}
                  onChange={this.handleRoleChange}
                  options={roleOptions}
                  isMulti={true}
                  placeholder="Roles"
                />
              </div>
              <div style={styles.filter}>
                <Select
                  value={selectedLocations}
                  onChange={this.handleLocationChange}
                  options={locationOptions}
                  isMulti={true}
                  placeholder="Locations"
                />
              </div>
              <div style={styles.submitContainer}>
                <SubmitHover style={styles.submitButton} onClick={this.submitFilters}>
                  Submit
                </SubmitHover>
              </div>
            </div>
          </td>
        </div>
      );

    }

    render () {
        return (
            <table cellPadding={0} cellSpacing={0} style={styles.companyListContainer}>
                <colgroup>
                    <col span="1" style={{width: '5%'}} />
                    <col span="1" style={{width: '25%'}} />
                    <col span="1" style={{width: '70%'}} />
                </colgroup>
                {this.renderFilters()}
                {this.renderHeader()}
                {this.renderCompanies()}
            </table>
        );
    }
}

export default CompanyJobList
