import React from "react";
import styled from "styled-components";

const styles = {
    container: {
        width: '100%',
        fontFamily: 'Lato, Arial',
        borderTop: '1px solid rgba(0, 0, 0, 0.1)',
        padding: '40px 20px',
        marginTop: 'auto',
        backgroundColor: '#FFF',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        boxSizing: 'border-box',
        justifySelf: 'flex-end'
    },
    title: {
        fontWeight: '700',
        fontSize: '18px',
        marginBottom: '15px',
        color: '#333',
    },
    link: {
        marginTop: '15px',
        fontSize: '16px',
        color: '#999',
        cursor: 'pointer',
    },
    logo: {
        fontWeight: '600',
        fontSize: '28px',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'flex-start',
        color: '#40bad5',
    },
    twitterColor: {
        backgroundColor: '#1da1f2',
    },
    instagramColor: {
        backgroundColor: '#111',
    },
    discordColor: {
        backgroundColor: '#7289DA',
    },
    icon: {
        width: '46px',
        height: '46px',
        borderRadius: '80px',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        fontSize: '24px',
        color: 'white',
        marginRight: '20px',
    },
    logoSection: {
        display: 'flex',
        alignItems: 'center',
    }
}

const Logo = styled.div`
transition: 0.2s;
:hover {
    opacity: 0.8 !important;
}
`

class Footer extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            mobile:  window.innerWidth < 1000
        }
    }

    componentDidMount() {
        window.addEventListener("resize", this.windowSizeChange.bind(this));
    }

    windowSizeChange = () => {
        this.setState({mobile: window.innerWidth < 1000, menuExpanded: false})
    }

    render() {
        return (
            <div style={styles.container}>
                <div style={{display: 'flex', justifyContent: 'space-between', flexDirection: this.state.mobile ? 'column' : 'row', maxWidth: '1200px', width: '100%'}}>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <a style={{alignSelf: 'flex-start'}} href={"/"}>
                            <div style={styles.logoSection}>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                    <Logo style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
                                        <div>
                                            <i style={{fontSize: '38px', color: '#ff5656'}} className="fas fa-crown" />
                                        </div>
                                        <div style={{...styles.logo, alignSelf: 'center' }}>
                                            <span style={{color: '#666', fontSize: '18px'}}>
                                                PRESTIGE
                                            </span><span style={{color: '#333', fontSize: '22px', marginTop: '-3px', fontWeight: '700'}}>
                                                FYI
                                            </span>
                                        </div>
                                    </Logo>
                                </div>
                            </div>
                        </a>
                        <div style={{maxWidth: '300px', fontWeight: '400', color: '#666', fontSize: '16px', marginTop: '20px', textAlign: this.state.mobile ? 'center' : 'left'}}>
                            Prestige is a tool to help you find, apply, and ace the interviews at top tech companies.
                        </div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: this.state.mobile ? 'center' : 'flex-end', width: '100%'}}>
                        <div style={{display: 'inline-flex', flexWrap: 'wrap', justifyContent: this.state.mobile ? 'center' : null}}>
                            <div style={{display: 'flex', flexDirection: 'column', marginTop: this.state.mobile ? '40px' : '0'}}>
                                <div style={styles.title}>
                                    Company
                                </div>
                                <a href={"/about"}>
                                    <div style={styles.link}>
                                        About Us
                                    </div>
                                </a>
                                <a href={"https://medium.com/@prestigefyi"} target={"_blank"}>
                                    <div style={styles.link}>
                                        Blog
                                    </div>
                                </a>
                            </div>
                            <div style={{marginLeft: '80px', display: 'flex', flexDirection: 'column', marginTop: this.state.mobile ? '40px' : '0'}}>
                                <div style={styles.title}>
                                    Information
                                </div>
                                <a href={"/terms"}>
                                    <div style={styles.link}>
                                        Terms of Service
                                    </div>
                                </a>
                                <a href={"/privacy"}>
                                    <div style={styles.link}>
                                        Privacy Policy
                                    </div>
                                </a>
                            </div>
                            <div style={{marginLeft: this.state.mobile ? '0' : '80px', display: 'flex', flexDirection: 'column', marginTop: this.state.mobile ? '40px' : '0'}}>
                                <div style={styles.title}>
                                    Support
                                </div>
                                <a href="mailto:contact@prestige.fyi">
                                    <div style={styles.link}>
                                        Contact Us
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{display: 'flex', marginTop: '40px', maxWidth: '1200px', width: '100%', borderTop: '1px solid rgba(255, 255, 255, 0.1)', paddingTop: '20px', justifyContent: this.state.mobile ? 'center' : null}}>
                    <a style={{...styles.icon, ...styles.twitterColor}} href={'https://twitter.com/prestigefyi'} target={"_blank"}>
                        <i className="fab fa-twitter"></i>
                    </a>
                    <a style={{...styles.icon, ...styles.instagramColor}} href={'https://medium.com/@prestigefyi'} target={"_blank"}>
                        <i className="fab fa-medium-m"></i>
                    </a>
                </div>
            </div>
        )
    }
}

export default Footer