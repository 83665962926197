import React from "react"
import Header from './Header'
import CompanyJobList from './CompanyJobList'
import AccountCreationBanner from "./AccountCreationBanner"
import Footer from './Footer'

const styles = {
    homeContainer: {
        width: '100%',
        fontFamily: 'Lato',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    companyListHolder: {
        maxWidth: '1024px',
        width: '100%',
        margin: '0 0 20px 0',
    },
}

class JobSearch extends React.Component {

    render () {
        return (
            <div style={styles.homeContainer}>
                <Header />
                <div style={{height: 20, width: '100%'}} />
                <AccountCreationBanner />
                <div style={styles.companyListHolder}>
                    <div style={{height: 20, width: '100%'}} />
                    <CompanyJobList {...this.props} />
                </div>
                <Footer />
            </div>
        );
    }
}

export default JobSearch
