import React, {useState, useRef, useEffect} from "react"
import logo from 'images/stallions2.png'
import CompanySearch from "./CompanySearch";
import styled from "styled-components"
import {GoogleOAuthProvider, useGoogleLogin} from "@react-oauth/google";
import utils from "../helpers/utils";

const styles = {
    headerContainer: {
        display: 'flex',
        justifyContent: 'space-around',
        borderBottom: '1px solid #EEE',
        width: '100%',
    },
    headerContainerMobile: {
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        borderBottom: '1px solid #EEE',
        width: '100%',
        paddingBottom: '20px',
        alignItems: 'center',
    },
    logoImage: {
        height: '60px',
    },
    logo: {
        fontWeight: '600',
        fontSize: '28px',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'flex-start',
        color: '#40bad5',
    },
    mainHeader: {
        padding: '20px',
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        gap: '20px',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    tagLine: {
        marginLeft: '10px',
        fontSize: '14px',
        color: "#999",
    },
    compare: {
        textDecoration: 'none',
    },
    logoSection: {
        display: 'flex',
        alignItems: 'center',
    },
    logoText: {
        marginLeft: '20px',
        fontSize: '24px',
    },
    logoSubtitle: {
        fontSize: '14px',
        color: '#999',
        marginTop: '5px',
    },
    loggedOutOptions: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    loggedOutOption: {
        textDecoration: 'none',
        marginLeft: '20px',
    },
    searchBox: {
        borderRadius: '20px',
        width: '300px',
    },
    menuSection: {
        display: 'flex',
        gap: '25px',
        alignItems: 'center'
    },
    secondaryButton: {
        border: '1px solid #CCC',
        padding: '10px 20px',
        borderRadius: '20px',
    }
}

const LoggedOutOption = styled.a`
text-decoration: none;
cursor: pointer;
color: #666;
margin-left: 20px;
:hover {
    color: #333 !important;
}
`

const MenuOption = styled.div`
background-color: white;
transition: 0.3s;
:hover {
    background-color: #EEE !important;
}
`

const Logo = styled.div`
transition: 0.2s;
:hover {
    opacity: 0.8 !important;
}
`


let Signup = (props) => {
    const [user, setUser] = useState(props.user)
    const [showDropdown, setShowDropdown] = useState(false)
    const [mobile, setMobile] = useState(window.innerWidth < 1000)

    const wrapperRef = useRef(null)
    const targetRef = useRef(null)

    useEffect(() => {
        setShowDropdown(false)

        return () => setShowDropdown(false)
    }, [])

    useEffect(() => {
        if (props.currentUser) {
            setUser(props.currentUser)
        }
    }, [props.currentUser])

    useEffect(() => {
        function handleClickOutside(event) {
            if (targetRef.current && !targetRef.current.contains(event.target)) {
                if (wrapperRef.current && wrapperRef.current.contains(event.target)) {

                } else {
                    setShowDropdown(false)
                }
            }

        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef]);

    const renderDropdown = () => {
        setShowDropdown(!showDropdown)
    }

    const googleLogin = useGoogleLogin({
        scope: "email profile",
        onSuccess: async tokenResponse => {
            let body = {
                access_token: tokenResponse.access_token
            }
            const token = document.querySelector('[name=csrf-token]').content
            const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}

            fetch(`${window.location.origin}/api/v0/auths`, {
                method: "POST",
                headers: headers,
                body: JSON.stringify(body),
            }).then(res => res.json())
                .then(res => {
                    if (res.success) {
                        setUser(res.user)
                        props.setUser && props.setUser(res.user)
                        parentThis.setState({askForLogin: false})
                    } else {

                    }
                })
                .catch(err => {

                })
        },
    });

    const logOut = () => {
        const token = document.querySelector('[name=csrf-token]').content
        const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}
        const body = {}

        fetch(`${window.location.origin}/api/v0/auths/logout`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(body),
        }).then(res => res.json())
            .then(res => {
                if (res.success) {
                    setUser(null)
                    props.setUser && props.setUser(null)
                    parentThis.setState({askForLogin: true})
                }
            })
            .catch(err => {
            })
    }

    if (user) {
        return (
            <div style={{display: 'flex', gap: '30px', alignItems: 'center'}}>
                {/*{renderPremium()}*/}
                <div>
                    <div ref={wrapperRef} onClick={() => renderDropdown()} style={{padding: '10px 20px', border: '1px solid #CCC', borderRadius: '20px', display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
                        <i style={{fontSize: '24px', marginRight: '10px', color: '#666'}} className="fas fa-bars" />
                        <i style={{fontSize: '28px'}} className="fas fa-user-circle" />
                    </div>
                    {showDropdown && (
                        <div ref={targetRef} style={{zIndex: 100, position: 'absolute', marginLeft: '-200px', boxShadow: 'rgb(0 0 0 / 15%) 0px 2px 8px', borderRadius: '20px', marginTop: '10px'}}>
                            <a href={"/dashboard"}>
                                <MenuOption style={{borderTopLeftRadius: '20px', borderTopRightRadius: '20px', padding: '20px', minWidth: '300px', backgroundColor: 'white', cursor: 'pointer', color: '#555'}}>
                                    Dashboard
                                </MenuOption>
                            </a>
                            <a href={"/premium"}>
                                <MenuOption style={{padding: '20px', minWidth: '300px', backgroundColor: 'white', cursor: 'pointer', color: '#555'}}>
                                    Get Premium
                                </MenuOption>
                            </a>
                            <a href={"/compare"}>
                                <MenuOption style={{padding: '20px', minWidth: '300px', backgroundColor: 'white', cursor: 'pointer', color: '#555'}}>
                                    Rank Companies
                                </MenuOption>
                            </a>
                            <a href={"https://medium.com/@prestigefyi"} target={"_blank"}>
                                <MenuOption style={{padding: '20px', minWidth: '300px', backgroundColor: 'white', cursor: 'pointer', color: '#555'}}>
                                    Blog
                                </MenuOption>
                            </a>
                            {
                                user.admin && (
                                    <a href={"/apply"}>
                                        <MenuOption style={{padding: '20px', minWidth: '300px', backgroundColor: 'white', cursor: 'pointer', color: '#555'}}>
                                            Admin Apply
                                        </MenuOption>
                                    </a>
                                )
                            }
                            <MenuOption onClick={() => logOut()} style={{borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px', padding: '20px', minWidth: '300px', backgroundColor: 'white', cursor: 'pointer', color: '#555'}}>
                                Logout
                            </MenuOption>
                        </div>
                    )
                    }
                </div>
            </div>
        )
    }

    return (
        <div style={{display: 'flex', gap: '30px', alignItems: 'center'}}>
            {/*{renderPremium()}*/}
            <div style={{...styles.secondaryButton, cursor: 'pointer', maxWidth: '300px', display: 'flex', alignItems: 'center'}} onClick={() => googleLogin()}>
                <img height={"20px"} width={"20px"} src={"https://staydropbucket.s3.amazonaws.com/google.png"} />
                <div style={{marginLeft: '10px'}}>Connect</div>
            </div>
        </div>
    )
}

let parentThis = this

class Header extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            mobile: window.innerWidth < 1000
        }
    }

    windowSizeChange = () => {
        this.setState({mobile: window.innerWidth < 1000})
    }

    componentDidMount() {
        window.addEventListener("resize", this.windowSizeChange.bind(this));
    }

    renderLoggedOutOptions = () => {
        let isTesting = window.location.host === "localhost:3000"

        let googleClientKey = "550406215467-i8kvabgk3dk9nh96l82jukkhijai3fe6.apps.googleusercontent.com"

        if (isTesting) {
            googleClientKey = "140207125595-29ih2v5auomi618v0p3tvi70dmami1nr.apps.googleusercontent.com"
        }

        return(
        <div style={styles.loggedOutOptions}>
            <GoogleOAuthProvider clientId={googleClientKey}>
                <Signup {...this.props} />
            </GoogleOAuthProvider>
        </div>
    )}

    renderLogo = () => {
        return (
            <div style={styles.logoSection}>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <a href={"/"}>
                        <Logo style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
                            <div>
                                <i style={{fontSize: '38px', color: '#ff5656'}} className="fas fa-crown" />
                            </div>
                            <div style={{...styles.logo, alignSelf: 'center' }}>
                                        <span style={{color: '#666', fontSize: '18px'}}>
                                            PRESTIGE
                                        </span><span style={{color: '#333', fontSize: '22px', marginTop: '-3px', fontWeight: '700'}}>
                                            FYI
                                        </span>
                            </div>
                        </Logo>
                    </a>
                </div>
            </div>
        )
    }

    render () {
        if (this.state.mobile) {
            return (
                <div style={styles.headerContainerMobile}>
                    <div style={{...styles.mainHeader, padding: '20px 20px 0'}}>
                        {this.renderLogo()}
                        {this.renderLoggedOutOptions()}
                    </div>
                    <div>
                        <div style={styles.searchBox} className={"no-outline"}>
                            <CompanySearch {...this.props} />
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div style={styles.headerContainer}>
                <div style={styles.mainHeader}>
                    {this.renderLogo()}
                    <div style={{display: 'flex', gap: '20px'}}>
                        <div style={styles.searchBox} className={"no-outline"}>
                            <CompanySearch {...this.props} />
                        </div>
                    </div>
                    {this.renderLoggedOutOptions()}
                </div>
            </div>
        );
    }
}

export default Header
