import React from "react"

const styles = {
    leftInfo: {
        display: 'flex',
        alignItems: 'center',
    },
    name: {
        marginLeft: 10,
    }
}

class JobListItem extends React.Component {
    containerStyle = () => {
        const style = {
            padding: '15px',
        }
        if (!this.props.last) {
            style.borderBottom = '1px'
            style.borderBottomColor = '#CCC'
            style.borderBottomStyle = 'solid'
        } else {
            style.borderBottomLeftRadius = '10px'
            style.borderBottomRightRadius = '10px'
        }
        if (this.props.index % 2 === 0) {
            style.backgroundColor = '#F4F4F4'
        }
        if (this.props.index === 0) {
            style.borderTopLeftRadius = '10px'
            style.borderTopRightRadius = '10px'
        }
        return style
    }

    render () {
        return (
            <div style={this.containerStyle()}>
                <div style={styles.leftInfo}>
                    <div style={styles.name}>
                        <h4>{this.props.title}</h4>
                        <p>{this.props.locations}</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default JobListItem
