import React from "react"
import styled from "styled-components";

const Input = styled.input`
    box-sizing: border-box;
    background-color: white;
    border: 1px;
    border-color: #CCC;
    border-style: solid;
    width: 100%;
    border-radius: 5px;
    padding: 10px;
    font-size: 18px;
    ::placeholder {
        color: #CCC;
    }
`

class InputText extends React.Component {
    render () {
        return (
            <Input
                onChange={e => this.props.onChange(e)}
                type={this.props.type || "text"}
                name={this.props.name}
                placeholder={this.props.placeholder || this.props.name}
                value={this.props.value}
            />
        );
    }
}

export default InputText
