import React from "react"
import Header from './Header'
import Footer from "./Footer"

const styles = {
    homeContainer: {
        width: '100%',
        fontFamily: 'Lato',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    termsContainer: {
        maxWidth: '1024px',
        width: '100%',
        margin: '0 0 20px 0',
    },
}

class Admin extends React.Component {
    render () {
        return (
            <div style={styles.homeContainer}>
                <Header {...this.props} />
                <div style={{height: 20, width: '100%'}} />
                <div style={styles.termsContainer}>
                    <div>
                        Admin
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default Admin
