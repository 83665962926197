import React from "react"
import Header from './Header'
import Footer from "./Footer"

const styles = {
    homeContainer: {
        width: '100%',
        fontFamily: 'Lato',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    termsContainer: {
        maxWidth: '1024px',
        width: '100%',
        margin: '0 0 20px 0',
    },
}

class About extends React.Component {

    renderTerms = () => (
        <div style={{fontSize: '16px'}}>
            <h2>
                About Us
            </h2>
            <p>
                Prestige.fyi is a tool I created after going through the whole job search process within tech. I wrote up a blog post on my job search strategies and experience <a style={{color: '#56b9ff'}} href={"https://medium.com/@prestigefyi/hacking-the-tech-job-hunt-895be98bada0"}>here</a>. After finishing the post I noticed there was a ton of manual legwork that I had to go through and decided I wanted to build a tool to automate as many of the manual processes as possible.
            </p>
            <p>
                I realized there were 3 things that I spent quite a bit of time doing that were necessary, but had no useful tools to automate the process. They were as follows:
            </p>
            <ul style={{marginLeft: '20px'}}>
                <li>
                    • Finding companies which would meet the salary expectations for my level
                </li>
                <li>
                    • Finding relevant postings on the career pages of those sites & sending in applications
                </li>
                <li>
                    • Finding interview questions that were likely to be asked so I could prepare for them
                </li>
            </ul>
            <p>
                Prestige.fyi is meant to solve these pain points with an easy way to filter companies that meet your salary expectations, automatically apply via career pages on your behalf, and collect interview questions that each company asks to make Prestige the go to source when it comes to studying for your phone screens and onsites.
            </p>
        </div>
    )

    render () {
        return (
            <div style={styles.homeContainer}>
                <Header {...this.props} />
                <div style={{height: 20, width: '100%'}} />
                <div style={styles.termsContainer}>
                    {this.renderTerms()}
                </div>
                <Footer />
            </div>
        );
    }
}

export default About
