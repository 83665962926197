import React from "react"
import styled from 'styled-components'
import JobListItem from './JobListItem'

const styles = {
    leftInfo: {
        alignItems: 'center',
        height: 'inherit',
        padding: '15px',
    },
    mainInfo: {
        marginLeft: 20,
    },
    name: {
        fontWeight: '600',
        fontSize: '20px',
    },
    elo: {
        fontWeight: '400',
        fontSize: '14px',
        color: '#999',
        marginTop: '4px',
    },
    companyInfo: {
        display: 'flex',
        alignItems: 'center',
    },
    centeredColumn: {
        padding: '0 15px',
        textAlign: 'center',
    },
    column: {
        padding: '0 15px',
    },
    rightInfo: {
        padding: '0 15px',
    },
    jobs: {
        padding: '0 15px',
        maxWidth: '200px',
        fontSize: '12px',
        wordWrap: 'break-word',
    },
    index: {
        color: '#999',
    },
    careersLink: {
        color: '#70b5ff',
    },
    quickViewJobs: {
        color: 'white',
        fontSize: '12px',
        fontWeight: '600',
        backgroundColor: '#70b5ff',
        padding: '10px',
        display: 'inline-block',
        marginBottom: '4px',
    },
    secondaryHover: {
        fontSize: '12px',
        fontWeight: '600',
        backgroundColor: 'white',
        padding: '10px',
        display: 'inline-block',
        marginBottom: '4px',
    },
    jobListingTitle: {
        fontSize: 14,
        padding: 10,
    }
}

const HoverStyle = styled.div`
:hover {
    background-color: #EEE !important;
}
`

class CompanyListItem extends React.Component {
    containerStyle = () => {
        const style = {
            height: '80px',
        }
        if (!this.props.last) {
            style.borderBottom = '1px'
            style.borderBottomColor = '#CCC'
            style.borderBottomStyle = 'solid'
        } else {
            style.borderBottomLeftRadius = '10px'
            style.borderBottomRightRadius = '10px'
        }
        if (this.props.index % 2 === 0) {
            style.backgroundColor = '#F9F9F9'
        }
        return style
    }


    limitSize = (string, chars) => {
        string = string.substr(0, chars)
        if (string.length === chars) {
            string += '...'
        }
        return string
    }

    renderJobListings = (jobs) => {
        return jobs.map((job_listing, index) => {
            return (
                <div style={styles.jobListingTitle}>
                    <a href={job_listing.link} target="_blank">{job_listing.title} - {job_listing.locations}</a>
                </div>
            )
        })
    }

    renderJobs = (jobs) => jobs.map((job, index) => this.renderJobListItem(job, (index + 1) === jobs.length, index))

    renderDepartments = () => {
        return this.props.jobs.map((job, index) => {
            return (
                <div>
                    <h3>{job.department}</h3>
                    {this.renderJobListings(job.jobs)}
                </div>
            )
        })
    }

    render () {
        const logoUrl = this.props.logo_url ? this.props.logo_url : `//logo.clearbit.com/${this.props.link}`

        return (
            <HoverStyle style={this.containerStyle()} key={this.props.index}>
                <td style={{...styles.leftInfo, textAlign: 'center'}} valign="top">
                    <p style={styles.index}>
                        {this.props.rank}
                    </p>
                </td>
                <td style={styles.leftInfo} valign="top">
                    <a href={`/companies/${this.props.slug}`}>
                        <div style={styles.companyInfo}>
                            <img width="45px" style={{objectFit: 'contain' }} src={logoUrl} />
                            <div style={styles.mainInfo}>
                                <div style={styles.name}>
                                    { `${this.props.name}` }
                                </div>
                                <div style={styles.elo}>
                                    <span>
                                        {`Elo | `}
                                    </span>
                                    <span>
                                        { `${Math.floor(this.props.elo)}` }
                                    </span>
                                </div>
                            </div>
                        </div>
                    </a>
                </td>
                <td style={styles.jobs}>
                    {this.renderDepartments()}
                </td>
            </HoverStyle>
        );
    }
}

export default CompanyListItem
