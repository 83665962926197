import React from "react"
import Header from './Header'
import Footer from "./Footer"
import styled from "styled-components";

const styles = {
    homeContainer: {
        width: '100%',
        fontFamily: 'Lato',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    premiumContainer: {
        maxWidth: '1024px',
        width: '100%',
        margin: '0 0 20px 0',
        padding: '50px 10px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        gap: '20px'
    },
    primaryButton: {
        backgroundColor: '#56b9ff',
        color: 'white',
        padding: '10px 20px',
        borderRadius: '20px',
        fontWeight: '600',
        cursor: 'pointer',
    },
    optionTitle: {
        fontWeight: '500',
        fontSize: '24px'
    },
    optionPrice: {
        fontWeight: '600',
        fontSize: '30px'
    }
}

const PrimaryButton = styled.div`
transition: 0.2s;
:hover {
    background-color: #ff7171 !important;
}
`

const PrimaryButton2 = styled.div`
transition: 0.2s;
:hover {
    background-color: #76c5ff !important;
}
`

class Premium extends React.Component {

    subscribe = (period) => {
        const token = document.querySelector('[name=csrf-token]').content
        const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}
        const body = {
            period
        }

        fetch(`${window.location.origin}/api/v0/checkouts`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(body),
        }).then(res => res.json())
            .then(res => {
                if (res.success) {
                    window.location.href = res.session_url
                }
            })
            .catch(err => {
            })
    }

    render () {
        return (
            <div style={styles.homeContainer}>
                <Header {...this.props} />
                <div style={{height: 20, width: '100%'}} />
                <div style={styles.premiumContainer}>
                    <div style={{fontWeight: 600, fontSize: '36px', color: '#333'}}>
                        Premium
                    </div>
                    <div style={{fontSize: '24px', color: '#666', textAlign: 'center'}}>
                        Get full access to all questions & answers on Prestige!
                    </div>
                    <div style={{display: 'flex', flexWrap: 'wrap', gap: '50px', color: '#333', margin: '50px 0'}}>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px', padding: '0 0 50px', borderRadius: '20px', boxShadow: 'rgb(0, 0, 0, 0.15) 0px 2px 8px', backgroundColor: 'white'}}>
                            <div style={{backgroundColor: '#56b9ff', width: '100%', height: '20px', marginBottom: '30px', borderTopLeftRadius: '20px', borderTopRightRadius: '20px'}} />
                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '50px', padding: '0 50px'}}>
                                <div style={styles.optionTitle}>
                                    Monthly
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                                    <div>
                                        Get full access to all company questions
                                    </div>
                                    <div>
                                        Get full access to custom written answers
                                    </div>
                                    <div>
                                        Priority access to Auto Apply with 100+ applications
                                    </div>
                                </div>
                                <div style={styles.optionPrice}>
                                    $49.00
                                    <span style={{fontSize: '18px', color: '#666'}}>
                                        /month
                                    </span>
                                </div>
                                <PrimaryButton2 onClick={() => this.subscribe('monthly')} style={styles.primaryButton}>
                                    Subscribe
                                </PrimaryButton2>
                            </div>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px', padding: '0 0 50px', borderRadius: '20px', boxShadow: 'rgb(0, 0, 0, 0.15) 0px 2px 8px', backgroundColor: 'white'}}>
                            <div style={{backgroundColor: '#ff5656', width: '100%', height: '20px', marginBottom: '30px', borderTopLeftRadius: '20px', borderTopRightRadius: '20px'}} />
                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '50px', padding: '0 50px'}}>
                                <div style={styles.optionTitle}>
                                    Yearly
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                                    <div>
                                        Get full access to all company questions
                                    </div>
                                    <div>
                                        Get full access to custom written answers
                                    </div>
                                    <div>
                                        Priority access to Auto Apply with 100+ applications
                                    </div>
                                </div>
                                <div style={styles.optionPrice}>
                                    $199.00
                                    <span style={{fontSize: '18px', color: '#666'}}>
                                        /year
                                    </span>
                                </div>
                                <PrimaryButton onClick={() => this.subscribe('yearly')} style={{...styles.primaryButton, backgroundColor: '#ff5656'}}>
                                    Subscribe
                                </PrimaryButton>
                            </div>
                        </div>
                    </div>

                    <div style={{textAlign: 'center', marginTop: '20px', maxWidth: '800px'}}>
                        <div style={{fontWeight: 500, fontSize: '24px', color: '#333'}}>
                            Do I have to get Premium to access company questions & answers?
                        </div>
                        <div style={{color: '#666', marginTop: '10px', fontSize: '16px'}}>
                            <p>
                                <b>NOPE!</b> If you would like to access specific company questions & answers all you need to do is contribute the questions you were asked during your interviews to temporarily unlock the company interview questions you are interested in.
                            </p>
                            <p>
                                In fact, <b>its preferred you unlock full access by contributing</b> rather than paying. This allows the information on Prestige to be more useful.
                            </p>
                            <p>
                                Purchasing premium is meant to be used by people who want <b>instant access</b> without contributing or just wish to <b>support the site</b>.
                            </p>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default Premium
