import React, {useState, useEffect} from "react"
import styled from "styled-components"
import FileUpload from "./FIleUpload";
import Dropdown from "./Dropdown";
import Checkbox from "./Checkbox";

const styles = {
    circle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '40px',
        width: '40px',
        border: '5px solid #CCC',
        borderRadius: '50%',
        transition: '0.3s',
        position: 'relative',
    },
    line: {
        width: '150px',
        height: '2px',
        transition: '0.3s',
        backgroundColor: '#CCC'
    },
    emptyButton: {
        userSelect: 'none',
        fontSize: '20px',
        padding: '10px 30px',
        borderRadius: '10px',
        width: '250px',
        textAlign: 'center',
        border: '1px solid #CCC',
        color: '#666',
        cursor: 'not-allowed'
    },
    activeButton: {
        cursor: 'pointer',
        border: '1px solid #ff5656',
        backgroundColor: '#ff5656',
        color: '#FFF',
        fontWeight: '600'
    },
    input: {
        fontWeight: '500',
        padding: '10px',
        width: '100%',
        color: '#333',
        borderRadius: '10px',
        border: '1px solid #AAA',
        fontSize: '16px'
    },
    infoInfo: {
        color: '#333',
        fontWeight: '500',
        fontSize: '14px'
    },
    notificationTitle: {
        fontWeight: '500',
        fontSize: '16px',
        color: '#333',
        marginLeft: '10px',
        maxWidth: '300px'
    },
    selectButton: {
        fontSize: '14px',
        fontWeight: '400',
        color: '#999',
        padding: '10px 20px',
        border: '1px solid #CCC',
        borderRadius: '10px',
        cursor: 'pointer'
    }
}

const PrimaryButton = styled.div`
transition: 0.2s;
:hover {
    background-color: #ff7171 !important;
}
`

const SecondaryButton = styled.div`
transition: 0.2s;
:hover {
    background-color: #333 !important;
    color: #FFF !important;
    border: 1px solid #333 !important;
}
`

const Company = ({company, selected, updateSelected}) => {
    const logoUrl = company.logo_url ? company.logo_url : `//logo.clearbit.com/${company.link}`

    // useEffect(() => {
    //     console.log("selected", selected)
    // }, [selected])

    const convertSalaryToString = salary => {
        salary = parseInt(salary)
        if (isNaN(salary)) {
            return '???'
        }
        salary /= 1000
        return `$${salary}K`
    }

    return (
        <div onClick={() => updateSelected(company)} style={{cursor: 'pointer', width: '350px', padding: '20px', border: '1px solid #CCC', backgroundColor: '#FFF', borderRadius: '20px', display: 'flex', gap: '10px', alignItems: 'center'}}>
            <Checkbox active={selected} updateFn={() => {}} model={'something'} field={'selected'} />
            <img width="45px" height="45px" style={{objectFit: 'contain', borderRadius: company.rounded ? '50%' : '0%', marginLeft: '20px'}} src={logoUrl}/>
            <div style={{display: 'flex', flexDirection: 'column', gap: '5px', width: '100%'}}>
                <div style={{fontWeight: '500', fontSize: '18px', color: '#333', width: '100%', display: 'flex', gap: '10px', alignItems: 'center', justifyContent: 'space-between'}}>
                    <div>
                        {company.name}
                    </div>
                    <div style={{fontWeight: '400', fontSize: '12px', color: '#666'}}>
                        {convertSalaryToString(company.salaryAtLevel) + ' TC'}
                    </div>
                </div>
                <div style={{display: 'flex', gap: '20px'}}>

                    <div style={{...styles.infoInfo, fontWeight: '600', color: company.is_remote ? '#ff5656' : '#333'}}>
                        {company.is_remote ? `Remote` : 'In Office'}
                    </div>
                    <div style={{...styles.infoInfo, fontWeight: '600', color: company.is_public ? '#ff5656' : '#333'}}>
                        {company.is_public ? `Public` : 'Private'}
                    </div>
                </div>
            </div>
        </div>
    )
}

class AutoApplySetup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            autoApplyPage: 1,
            uploadedResume: null,
            resume: null,
            file: null,
            user: null,
            first_name: "",
            last_name: "",
            email: "",
            phone_number: "",
            city: "",
            linkedin: "",
            authorized: "Yes",
            sponsorship: "No",
            level: props.levelOption || 'Senior',
            selected: false,
            levelOption: props.levelOption || "Senior",
            salary: props.salary || "",
            location: props.location || "Anywhere",
            publicOnly: props.publicOnly || false,
            remoteFirst: props.remoteFirst || false,
            companiesSelected: new Set(),
            experience_match: 'Great matches only',
            number_of_roles: "1",
            agree1: false,
            agree2: false,
            loading: true,
            finalLoading: false,
            mobile: window.innerWidth < 1000
        }
    }

    componentDidMount() {
        this.fetchResume()
        this.fetchUser()
        window.addEventListener("resize", this.windowSizeChange.bind(this));
    }

    windowSizeChange = () => {
        this.setState({mobile: window.innerWidth < 1000})
    }

    renderAutoNav = () => {
        const pages = ['Resume', 'Personal Info', 'Select Companies', 'Finalize Choices']

        return pages.map((page, index) => {
            const passedPage = this.state.autoApplyPage > index
            const onPage = this.state.autoApplyPage - 1 === index

            return (
                <>
                    <div style={{...styles.circle, width: this.state.mobile ? '20px' : '40px', height: this.state.mobile ? '20px' : '40px', backgroundColor: (passedPage && !onPage) ? '#ff5656' : '#FFF', border: passedPage ? '5px solid #ff5656' : '5px solid #CCC'}}>
                        {!this.state.mobile && <i style={{fontSize: '20px', color: '#FFF'}} className="fas fa-check" />}
                        <div style={{position: 'absolute', width: '150px', left: '-60px', top: '40px', textAlign: 'center', color: onPage ? '#666' : '#999'}}>
                            {this.state.mobile ? '' : page}
                        </div>
                    </div>
                    {index < pages.length - 1 && <div style={{...styles.line, width : this.state.mobile ? '50px' : '150px', backgroundColor: (passedPage && !onPage) ? '#ff5656' : '#CCC'}} />}
                </>
            )
        })
    }

    setPage = (dif) => {
        if (this.state.finalLoading) {
            return
        }
        if (dif === 1) {
            if (this.state.autoApplyPage >= 4) {
                this.setState({finalLoading: true})
                this.updateAutoApply()
            } else {
                if (this.state.autoApplyPage === 2) {
                    this.updateUser()
                }
                if (this.state.autoApplyPage === 3) {
                    this.updateCompanies()
                }
                this.setState({autoApplyPage: this.state.autoApplyPage + 1})
            }
        }
        if (dif === -1) {
            if (this.state.autoApplyPage === 0) {

            } else {
                this.setState({autoApplyPage: this.state.autoApplyPage - 1})
            }
        }
    }

    fetchUser = () => {
        const token = document.querySelector('[name=csrf-token]').content
        const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}
        const body = {}

        fetch(`${window.location.origin}/api/v0/users/${this.props.currentUser.id}`, {
            method: "GET",
            headers: headers,
        }).then(res => res.json())
            .then(res => {
                if (res.success) {
                    let companiesSelected = new Set()
                    for (let i = 0; i < res.applications.length; i++) {
                        companiesSelected.add(res.applications[i].company_id)
                    }
                    this.setState({
                        user: res.user,
                        first_name: res.user.first_name || "",
                        last_name: res.user.last_name || "",
                        email: res.user.email || "",
                        phone_number: res.user.phone_number || "",
                        city: res.user.city || "",
                        linkedin: res.user.linkedin || "",
                        authorized: res.user.work_authorized || "Yes",
                        sponsorship: res.user.require_sponsorship || "No",
                        experience_match: res.user.experience_match || 'Great matches only',
                        number_of_roles: res.user.number_of_roles || "1",
                        companiesSelected
                    })
                }
            })
            .catch(err => {
            })
    }

    fetchResume = () => {
        const token = document.querySelector('[name=csrf-token]').content
        const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}
        const body = {}

        fetch(`${window.location.origin}/api/v0/resumes`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(body),
        }).then(res => res.json())
            .then(res => {
                if (res.success) {
                    this.setState({resume: res.resume, uploadedResume: res.uploaded_resume, loading: false})
                }
            })
            .catch(err => {
            })
    }

    updateCompanies = () => {
        const token = document.querySelector('[name=csrf-token]').content
        const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}
        const body = {company_ids: Array.from(this.state.companiesSelected)}

        fetch(`${window.location.origin}/api/v0/applications`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(body),
        }).then(res => res.json())
            .then(res => {
                if (res.success) {

                }
            })
            .catch(err => {
            })
    }

    updateUser = async() => {
        const token = document.querySelector('[name=csrf-token]').content
        const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}

        const {first_name, last_name, linkedin, email, phone_number, city, authorized, sponsorship} = this.state

        const body = {
            user: {
                first_name,
                last_name,
                linkedin,
                email,
                phone_number,
                city,
                work_authorized: authorized,
                require_sponsorship: sponsorship
            }
        }

        fetch(`${window.location.origin}/api/v0/users/${this.props.currentUser.id}`, {
            method: "PATCH",
            headers: headers,
            body: JSON.stringify(body),
        }).then(res => res.json())
            .then(res => {
                if (res.success) {

                }
            })
            .catch(err => {
            })
    }

    updateAutoApply = async() => {
        const token = document.querySelector('[name=csrf-token]').content
        const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}

        const {number_of_roles, experience_match} = this.state

        const body = {
            user: {
                auto_apply_on: true,
                experience_match,
                number_of_roles
            }
        }

        let parentThis = this

        fetch(`${window.location.origin}/api/v0/users/${this.props.currentUser.id}`, {
            method: "PATCH",
            headers: headers,
            body: JSON.stringify(body),
        }).then(res => res.json())
            .then(res => {
                if (res.success) {
                    parentThis.props.hideModal && parentThis.props.hideModal()
                }
            })
            .catch(err => {
            })
    }

    updateResume = async(resume_id, file) => {
        const token = document.querySelector('[name=csrf-token]').content
        const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}
        const body = {resume: {uploaded: true, name: file.name}}

        fetch(`${window.location.origin}/api/v0/resumes/${resume_id}`, {
            method: "PATCH",
            headers: headers,
            body: JSON.stringify(body),
        }).then(res => res.json())
            .then(res => {
                if (res.success) {
                    this.setState({uploadedResume: res.resume})
                }
            })
            .catch(err => {
            })
    }

    uploadCallback = async(res, file) => {
        this.setState({file})
        await this.updateResume(this.state.resume.id, file)
    }

    updateSelected = (company) => {
        if (this.state.companiesSelected.has(company.id)) {
            this.state.companiesSelected.delete(company.id)
        } else {
            this.state.companiesSelected.add(company.id)
        }
        this.setState({companiesSelected: this.state.companiesSelected})
    }

    convertAnythingToMoney = (str) => {
        let hasDollar = false
        if (str[0] === '$') {
            hasDollar = true
            str = str.substr(1)
        }
        if (str[0] === '.') {
            str = '0' + str
        }

        let foundDecimal = false
        let afterDecimal = 0
        const numbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', ',']

        for (let i = 0; i < str.length; i++) {
            if (numbers.includes(str[i])) {
                if (foundDecimal) {
                    afterDecimal++
                    if (afterDecimal === 3) {
                        return false
                    }
                }
            } else if (str[i] === '.') {
                if (foundDecimal) {
                    return false
                }
                foundDecimal = true
            } else {
                return false
            }
        }

        let endIndex = str.indexOf('.')
        if (endIndex === -1) {
            endIndex = str.length
        }

        if (str.length === 0) {
            if (hasDollar) {
                return '$'
            }
            return ''
        }

        str = Number(str.substr(0, endIndex).replace(/,/g,'')).toLocaleString("en-US") + str.substr(endIndex)

        return '$' + str
    }

    onMoneyChange = (e) => {
        const res = this.convertAnythingToMoney(e.target.value)
        if (res !== false) {
            this.setState({[e.target.name]: res})
        }
    }

    updateAgree1 = (agree1) => {
        this.setState({agree1})
    }

    updateAgree2 = (agree2) => {
        this.setState({agree2})
    }

    updateVisible = (publicOnly) => {
        this.setState({publicOnly})
    }

    updateRemote = (remoteFirst) => {
        this.setState({remoteFirst})
    }

    changeSelection = (select, companies) => {
        for (let i = 0; i < companies.length; i++) {
            if (select === "select") {
                this.state.companiesSelected.add(companies[i].id)
            }
            if (select === "unselect") {
                this.state.companiesSelected.delete(companies[i].id)
            }
        }

        this.setState({companiesSelected: this.state.companiesSelected})
    }

    renderCompanySelect = () => {

        let { levelOption, salary, remoteFirst, publicOnly, location } = this.state

        salary = Number(salary.replace(/\D/g,'')) || 0

        let matchedCompanies = []
        let unmatchedCompanies = []

        for (let i = 0; i < this.props.companies.length; i++) {
            let company = this.props.companies[i]
            let salaryAtLevel = null
            let isMatched = true

            if (location !== 'Anywhere') {
                if (!company.locations.includes(location) && !company.is_remote) {
                    isMatched = false
                }
            }

            if (levelOption === 'Entry') {
                salaryAtLevel = company.salary_entry_amount
                if (company.salary_entry_amount < salary) {
                    isMatched = false
                }
            }

            if (levelOption === 'Mid') {
                salaryAtLevel = company.salary_midlevel_amount
                if (company.salary_midlevel_amount < salary) {
                    isMatched = false
                }
            }

            if (levelOption === 'Senior') {
                salaryAtLevel = company.salary_senior_amount
                if (company.salary_senior_amount < salary) {
                    isMatched = false
                }
            }

            if (levelOption === 'Staff') {
                salaryAtLevel = company.salary_staff_amount
                if (company.salary_staff_amount < salary) {
                    isMatched = false
                }
            }

            if (levelOption === 'Sr. Staff') {
                salaryAtLevel = company.salary_senior_staff_amount
                if (company.salary_senior_staff_amount < salary) {
                    isMatched = false
                }
            }

            company.salaryAtLevel = salaryAtLevel

            if (publicOnly && !company.is_public) {
                isMatched = false
            }

            if (remoteFirst && !company.is_remote) {
                isMatched = false
            }

            if (isMatched) {
                matchedCompanies.push(company)
            } else {
                unmatchedCompanies.push(company)
            }
        }

        return (
            <div style={{paddingBottom: '100px'}}>
                <div style={{display: 'flex', gap: '20px', flexWrap: 'wrap', marginBottom: '20px', alignItems: 'center', justifyContent: 'center'}}>
                    <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                        <div style={{fontWeight: '500', fontSize: '14px', color: '#666', maxWidth: '200px'}}>
                            What level are you targeting?
                        </div>
                        <Dropdown options={["Entry", "Mid", "Senior", "Staff", "Sr. Staff"]} selected={this.state.levelOption} onSelect={(levelOption) => this.setState({levelOption})} />
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                        <div style={{fontWeight: '500', fontSize: '14px', color: '#666'}}>
                            Minimum TC
                        </div>
                        <input style={{...styles.input, maxWidth: this.state.mobile ? '120px' : '300px'}} name={"salary"} placeholder={"$100,000"} value={this.state.salary} onChange={(e) => this.onMoneyChange.call(this, e)} />
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                        <div style={{fontWeight: '500', fontSize: '14px', color: '#666', maxWidth: '200px'}}>
                            Location
                        </div>
                        <Dropdown options={["Anywhere", "San Francisco, CA", "New York, NY", "Seattle, WA"]} selected={this.state.location} onSelect={(location) => this.setState({location})} />
                    </div>
                    <div style={{display: 'flex', flexDirection: this.state.mobile ? 'row' : 'column', gap: '20px', flexWrap: this.state.mobile ? 'wrap' : 'nowrap'}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Checkbox active={this.state.publicOnly} updateFn={this.updateVisible.bind(this)} model={'something'} field={'publicOnly'} />
                            <div style={styles.notificationTitle}>
                                Public Only
                            </div>
                        </div>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Checkbox active={this.state.remoteFirst} updateFn={this.updateRemote.bind(this)} model={'something'} field={'remoteFirst'} />
                            <div style={styles.notificationTitle}>
                                Remote
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{justifyContent: 'center', marginBottom: '20px', fontSize: '20px', fontWeight: '600', color: '#333', textAlign: 'center', width: '100%', display: 'flex', gap: '20px', alignItems: 'center'}}>
                    <div>
                        Matching Companies
                    </div>
                    <SecondaryButton onClick={() => this.changeSelection("unselect", matchedCompanies)} style={styles.selectButton}>
                        Unselect All
                    </SecondaryButton>
                    <SecondaryButton onClick={() => this.changeSelection("select", matchedCompanies)} style={styles.selectButton}>
                        Select All
                    </SecondaryButton>
                </div>
                <div style={{display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: '20px', justifyContent: 'center'}}>
                    {matchedCompanies.map(company => (<Company company={company} selected={this.state.companiesSelected.has(company.id)} updateSelected={this.updateSelected} />))}
                </div>
                { unmatchedCompanies.length > 0 && (
                    <>
                        <div style={{width: '100%', height: '1px', backgroundColor: '#CCC', margin: '20px 0'}}>

                        </div>
                        <div style={{justifyContent: 'center', marginBottom: '20px', fontSize: '20px', fontWeight: '600', color: '#333', textAlign: 'center', width: '100%', display: 'flex', gap: '20px', alignItems: 'center'}}>
                            <div>
                                Other Companies
                            </div>
                            <SecondaryButton onClick={() => this.changeSelection("unselect", unmatchedCompanies)} style={styles.selectButton}>
                                Unselect All
                            </SecondaryButton>
                            <SecondaryButton onClick={() => this.changeSelection("select", unmatchedCompanies)} style={styles.selectButton}>
                                Select All
                            </SecondaryButton>
                        </div>
                        <div style={{display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: '20px', justifyContent: 'center'}}>
                            {unmatchedCompanies.map(company => (<Company company={company} selected={this.state.companiesSelected.has(company.id)} updateSelected={this.updateSelected} />))}
                        </div>
                    </>
                ) }
            </div>
        )
    }

    renderAutoPage = () => {
        if (this.state.loading) {
            return (
                <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#000'}}>
                    <div className={"lds-facebook"}>
                        <div />
                        <div />
                        <div />
                    </div>
                </div>
            )
        }

        if (this.state.autoApplyPage === 1) {
            return (
                <>
                    {this.state.resume && <FileUpload uploadedResume={this.state.uploadedResume} theFile={this.state.file} presignedUrl={this.state.resume.upload_url} uploadCallback={this.uploadCallback} />}
                </>
            )
        }

        if (this.state.autoApplyPage === 2) {
            return (
                <div style={{display: 'flex', gap: '50px', flexWrap: 'wrap', justifyContent: 'center', paddingBottom: '100px'}}>
                    <div style={{display: 'flex', flexDirection: 'column', gap: '30px'}}>
                        <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                            <div style={{fontWeight: '500', fontSize: '14px', color: '#666'}}>
                                First Name
                            </div>
                            <input style={{...styles.input, maxWidth: this.state.mobile ? '120px' : '300px'}} name={"first_name"} placeholder={"John"} value={this.state.first_name} onChange={(e) => this.setState({first_name: e.target.value})} />
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                            <div style={{fontWeight: '500', fontSize: '14px', color: '#666'}}>
                                Last Name
                            </div>
                            <input style={{...styles.input, maxWidth: this.state.mobile ? '120px' : '300px'}} name={"last_name"} placeholder={"Smith"} value={this.state.last_name} onChange={(e) => this.setState({last_name: e.target.value})} />
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                            <div style={{fontWeight: '500', fontSize: '14px', color: '#666'}}>
                                Email
                            </div>
                            <input style={{...styles.input, maxWidth: this.state.mobile ? '120px' : '300px'}} name={"email"} placeholder={"johnsmith@gmail.com"} value={this.state.email} onChange={(e) => this.setState({email: e.target.value})} />
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                            <div style={{fontWeight: '500', fontSize: '14px', color: '#666'}}>
                                Phone Number
                            </div>
                            <input style={{...styles.input, maxWidth: this.state.mobile ? '120px' : '300px'}} name={"phone_number"} placeholder={"(123) 456-7890"} value={this.state.phone_number} onChange={(e) => this.setState({phone_number: e.target.value})} />
                        </div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', gap: '30px'}}>
                        <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                            <div style={{fontWeight: '500', fontSize: '14px', color: '#666'}}>
                                LinkedIn
                            </div>
                            <input style={{...styles.input, maxWidth: this.state.mobile ? '120px' : '300px'}} name={"linkedin"} placeholder={"https://linkedin.com/in/johnsmith"} value={this.state.linkedin} onChange={(e) => this.setState({linkedin: e.target.value})} />
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                            <div style={{fontWeight: '500', fontSize: '14px', color: '#666'}}>
                                City
                            </div>
                            <input style={{...styles.input, maxWidth: this.state.mobile ? '120px' : '300px'}} name={"city"} placeholder={"San Francisco"} value={this.state.city} onChange={(e) => this.setState({city: e.target.value})} />
                        </div>
                    </div>

                    <div style={{display: 'flex', flexDirection: 'column', gap: '30px'}}>
                        <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                            <div style={{fontWeight: '500', fontSize: '14px', color: '#666', maxWidth: '200px'}}>
                                What level are you targeting?
                            </div>
                            <Dropdown options={["Entry", "Mid", "Senior", "Staff", "Sr. Staff"]} selected={this.state.level} onSelect={(level) => this.setState({level})} />
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                            <div style={{fontWeight: '500', fontSize: '14px', color: '#666', maxWidth: '200px'}}>
                                Are you authorized to work in the USA?
                            </div>
                            <Dropdown options={["Yes", "No"]} selected={this.state.authorized} onSelect={(authorized) => this.setState({authorized})} />
                        </div>

                        <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                            <div style={{fontWeight: '500', fontSize: '14px', color: '#666', maxWidth: '200px'}}>
                                Will you now or in the future require Visa Sponsorship?
                            </div>
                            <Dropdown options={["No", "Yes"]} selected={this.state.sponsorship} onSelect={(sponsorship) => this.setState({sponsorship})} />
                        </div>
                    </div>
                </div>
            )
        }

        if (this.state.autoApplyPage === 3) {
            return this.renderCompanySelect()
        }

        if (this.state.autoApplyPage === 4) {
            return (
                <div style={{display: 'flex', gap: '50px', flexWrap: 'wrap', justifyContent: 'center', paddingBottom: '100px'}}>
                    <div style={{display: 'flex', flexDirection: 'column', gap: '30px'}}>
                        <div style={{display: 'flex', flexDirection: 'column', gap: '10px', textAlign: 'center', alignItems: 'center'}}>
                            <div style={{fontWeight: '500', fontSize: '14px', color: '#666', maxWidth: '400px'}}>
                                How strictly should we ensure the job is relevant to your experience? (If no matches are found we will not apply for that company)
                            </div>
                            <Dropdown options={["Great matches only", "Okay matches only", "Any same discipline", "Apply for anything"]} selected={this.state.experience_match} onSelect={(experience_match) => this.setState({experience_match})} />
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', gap: '10px', textAlign: 'center', alignItems: 'center'}}>
                            <div style={{fontWeight: '500', fontSize: '14px', color: '#666', maxWidth: '400px'}}>
                                What is the maximum number of roles we should apply for per company?
                            </div>
                            <Dropdown options={["1", "2", "3", "4", "5"]} selected={this.state.number_of_roles} onSelect={(number_of_roles) => this.setState({number_of_roles})} />
                        </div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', gap: '30px'}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Checkbox active={this.state.agree1} updateFn={this.updateAgree1.bind(this)} model={'something'} field={'agree1'} />
                            <div style={{...styles.notificationTitle, color: '#999'}}>
                                I allow Prestige.fyi to submit resumes on my behalf
                            </div>
                        </div>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Checkbox active={this.state.agree2} updateFn={this.updateAgree2.bind(this)} model={'something'} field={'agree2'} />
                            <div style={{...styles.notificationTitle, color: '#999'}}>
                                I understand Prestige.fyi will attempt to fill in as accurate information as possible, but may be required to guess or make assumptions during the resume application process
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        return null
    }

    render() {
        let nextButtonActive = false
        const prevButtonActive = this.state.autoApplyPage > 1

        if (this.state.autoApplyPage === 1) {
            if (this.state.uploadedResume?.uploaded) {
                nextButtonActive = true
            }
        }

        if (this.state.autoApplyPage === 2) {
            nextButtonActive = true
            if (this.state.email.length < 3) {
                nextButtonActive = false
            }
            if (this.state.first_name.length < 1) {
                nextButtonActive = false
            }
            if (this.state.last_name.length < 1) {
                nextButtonActive = false
            }
            if (this.state.phone_number.length < 6) {
                nextButtonActive = false
            }
            if (this.state.linkedin.length < 6) {
                nextButtonActive = false
            }
            if (this.state.city.length < 2) {
                nextButtonActive = false
            }
        }

        if (this.state.autoApplyPage === 3) {
            nextButtonActive = this.state.companiesSelected.size > 0
        }

        if (this.state.autoApplyPage === 4) {
            nextButtonActive = true

            if (this.state.agree1 === false) {
                nextButtonActive = false
            }
            if (this.state.agree2 === false) {
                nextButtonActive = false
            }
        }

        return (
                <div style={{display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', gap: '20px', height: '100%'}}>
                    <div style={{fontWeight: '600', fontSize: '24px', color: '#333'}}>
                        Setup Auto Apply
                    </div>
                    <div style={{fontSize: '12px', color: '#999'}}>
                        * Non-premium user applications are restricted based on availability.
                    </div>
                    <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                        {this.renderAutoNav()}
                    </div>
                    <div style={{marginTop: '50px'}}>
                        {this.renderAutoPage()}
                    </div>

                    <div style={{borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px', zIndex: 550, backgroundColor: '#FFF', display: 'flex', alignItems: 'flex-end', width: 'calc(100% - 20px)', position: 'absolute', padding: '20px', bottom: '0', left: 10, right: 10}}>
                        <div style={{display: 'flex', justifyContent: 'space-between', width: '100%', gap: '10px'}}>
                            {
                                prevButtonActive ? (
                                    <PrimaryButton onClick={() => this.setPage(-1)} style={{...styles.emptyButton, ...(styles.activeButton)}}>
                                        Previous
                                    </PrimaryButton>
                                ) : (
                                    <div style={styles.emptyButton}>
                                        Previous
                                    </div>
                                )
                            }
                            {
                                nextButtonActive ? (
                                    <PrimaryButton onClick={() => this.setPage(1)} style={{...styles.emptyButton, ...(styles.activeButton)}}>
                                        Next
                                    </PrimaryButton>
                                ) : (
                                    <div style={styles.emptyButton}>
                                        Next
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
        )
    }
}

export default AutoApplySetup